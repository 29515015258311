const steps = [
    {
      title: 'Welcome (internal title)',
      index: 0,
      name: 'intent',
    },
    // {
    //   title: 'Intent (internal title)',
    //   index: 1,
    //   name: 'intent',
    // },
    {
      title: 'Basic Info (internal title)',
      index: 1,
      name: 'basic-info',
    },
    // {
    //   title: 'Preferences (internal title)',
    //   index: 2,
    //   name: 'preferences',
    // },
    {
      title: 'Image Overview (internal title)',
      index: 2,
      name: 'image-overview',
    },
    {
      title: 'Image (internal title)',
      index: 3,
      name: 'image-1',
    },
    {
      title: 'Image (internal title)',
      index: 4,
      name: 'image-2',
    },
    {
      title: 'Image (internal title)',
      index: 5,
      name: 'image-3',
    },
    {
      title: 'Image (internal title)',
      index: 6,
      name: 'image-4',
    },
    {
      title: 'Image (internal title)',
      index: 7,
      name: 'image-5',
    },
    {
      title: 'Submit (internal title)',
      index: 8,
      name: 'submit',
    },
    {
      title: 'Finished, next (internal title)',
      index: 10,
      name: 'finished',
    },
  ]

export default Object.freeze(steps)
