import imageApiServices from '@/views/widgets/services/image_endpoints'

import UserIntentOptionsList from '@/views/widgets/data/response_options/UserIntentOptionsList'
import UserPriorityOptionsList from '@/views/widgets/data/response_options/UserPriorityOptionsList'
import OverviewImages from '@/views/widgets/data/OverviewImages'
import FunnelFormStepsDefinition from '@/views/widgets/data/steps/FunnelFormStepsDefinition'

const ASTERIX_WILDCARD = '*' // NOTE: Defaults are also set in the locales.

const getters = {
  userResponses (state) {
    return state.userResponses
  },
  uploadImages (state) {
    return state.uploadImages
  },
  userIntentOptionsList (state) {
    return state.userIntentOptionsList
  },
  priorityOptionsList (state) {
    return state.priorityOptionsList
  },
  enumSteps (state) {
    return state.enumSteps
  },
  overviewImages (state) {
    return state.overviewImages
  },
  imageFunnelStartIndex (state) {
    return state.imageFunnelStartIndex
  },
  imagesToTakeByPointOfView (state) {
    return state.imagesToTakeByPointOfView
  },
  maxNrOfImagesToUpload (state) {
    return state.maxNrOfImagesToUpload
  },
  imageStepRange (state) {
    const minRange = state.imageFunnelStartIndex
    const maxRange = state.imageFunnelStartIndex + state.maxNrOfImagesToUpload
    return [minRange, maxRange]
  },
  isInformedConsentGiven (state) {
    return state.isInformedConsentGiven
  },
  ASTERIX_WILDCARD (state) {
    return state.ASTERIX_WILDCARD
  },
  explicitClinic (state) {
    return state.explicitClinic
  },
  // NOTE: Did not get 'step' to work properly because of setStep.
  // step (state) {
  //   return state.step
  // },
  availableSteps (state) {
    /** Returns an array of integers, representing steps.
     * The array is used as 'validation' on which steps
     * are available by enabling the 'Next' button. But
     * also note that it's not a "strict" validation, as
     * user can still slide onto unavailable steps.
     *
     * IMPORTANT:
     *   See `./data/FunnelFormStepsDefinition.js` for reference on
     *   each step. This is not a beautiful implementation
     *   but there are only two places to keep in sync.
     *   Here and `./data/FunnelFormStepsDefinition.js` (27/06/2020).
     */
    const steps = []

    const STEP_W_INTRO = 0
    const STEP_W_BASIC_INFO = 1
    const STEP_W_IMAGE_OVERVIEW = 2
    const STEP_W_IMAGE_0 = 3
    const STEP_W_IMAGE_1 = 4
    const STEP_W_IMAGE_2 = 5
    const STEP_W_IMAGE_3 = 6
    const STEP_W_IMAGE_4 = 7
    const STEP_W_FINAL_SUBMIT = 8
    const STEP_W_FINISH = 9

    if (!STEP_W_IMAGE_0 === state.imageFunnelStartIndex) {
      // Should match in current implementation. Delete when obsolete.
      throw new Error('Image 0 and image funnel start index do not match')
    }

    // Non-action steps, and/or always available
    steps.push(STEP_W_INTRO)
    steps.push(STEP_W_BASIC_INFO)
    steps.push(STEP_W_IMAGE_OVERVIEW)
    steps.push(STEP_W_IMAGE_0)
    steps.push(STEP_W_IMAGE_1)
    steps.push(STEP_W_IMAGE_2)
    steps.push(STEP_W_IMAGE_3)
    steps.push(STEP_W_IMAGE_4)
    steps.push(STEP_W_FINAL_SUBMIT)

    const intentKnown = (state.userResponses.userIntents.length !== 0)

    const basicInfoGiven = Boolean(
      state.userResponses.email &&
        state.userResponses.name
    )

    if (intentKnown) steps.push(STEP_W_BASIC_INFO)

    if (basicInfoGiven) steps.push(STEP_W_IMAGE_OVERVIEW)

    const image0 = state.uploadImages[0]
    const imageCompleted0 = Boolean(image0 && image0.file)
    const imageSkipped0 = Boolean(image0 && image0.skipped)
    if (imageCompleted0 || imageSkipped0) steps.push(STEP_W_IMAGE_1)

    const image1 = state.uploadImages[1]
    const imageCompleted1 = Boolean(image1 && image1.file)
    const imageSkipped1 = Boolean(image1 && image1.skipped)
    if (imageCompleted1 || imageSkipped1) steps.push(STEP_W_IMAGE_2)

    const image2 = state.uploadImages[2]
    const imageCompleted2 = Boolean(image2 && image2.file)
    const imageSkipped2 = Boolean(image2 && image2.skipped)
    if (imageCompleted2 || imageSkipped2) steps.push(STEP_W_IMAGE_3)

    const image3 = state.uploadImages[3]
    const imageCompleted3 = Boolean(image3 && image3.file)
    const imageSkipped3 = Boolean(image3 && image3.skipped)
    if (imageCompleted3 || imageSkipped3) steps.push(STEP_W_IMAGE_4)

    const image4 = state.uploadImages[4]
    const imageCompleted4 = Boolean(image4 && image4.file)
    const imageSkipped4 = Boolean(image4 && image4.skipped)
    if (imageCompleted4 || imageSkipped4) steps.push(STEP_W_FINAL_SUBMIT)

    if (state.isInformedConsentGiven) steps.push(STEP_W_FINISH)

    return steps
  },
}

const initialState = {
  step: 0,

  userResponses: {
    userIntents: [],
    pointOfViews: [], // NOTE: As of 2021/4 this is also 'topic-of-interests'
    year: '',
    name: '',
    primaryGoal: '',
    phone: '',
    email: '',
    socialSecurityNumber: null,
    feedback: null,
  },
  uploadImages: [],

  activeUserIntent: {},
  imagesToTakeByPointOfView: [ASTERIX_WILDCARD],
  defaultImagesToTakeByPointOfView: [ASTERIX_WILDCARD],
  isInformedConsentGiven: false,

  // User option lists (static)
  userIntentOptionsList: UserIntentOptionsList,
  priorityOptionsList: UserPriorityOptionsList,

  // Static
  overviewImages: OverviewImages,
  enumSteps: FunnelFormStepsDefinition,

  // IMPORTANT hardcoded values. NOTE: IT'S A COMMON BUG NOT TO UPDATE THIS :-)
  imageFunnelStartIndex: 3,
  maxNrOfImagesToUpload: 5,
  nrOfExpectedImageSlotsToRetrieve: 5,

  // Explicitly set clinic. For current implementation for enterprise clinics.
  explicitClinic: {
    title: '',
    uuid: '',
    groups: [],
  },

  // Constants
  ASTERIX_WILDCARD: ASTERIX_WILDCARD,
}

const mutations = {
  setUserResponse (state, payload) {
    const topic = payload.topic
    const response = payload.response
    if (state.userResponses[topic] === undefined) {
      throw Error(`Unknown user response topic: ${topic}`)
    }
    state.userResponses[topic] = response
  },
  setActiveImage (state, payload) {
    state.activeImage = payload
  },
  setUploadImages (state, payload) {
    state.uploadImages = payload
  },
  setActiveUserIntent (state, payload) {
    state.activeUserIntent = payload
  },
  setImagesToTakeByPointOfView (state, payload) {
    /** Think of this as changed by the user.
     */
    state.imagesToTakeByPointOfView = payload
  },
  setDefaultImagesToTakeByPointOfView (state, payload) {
    /** Think of this as 'pr. widget', and not changed by the user.
    */
    state.defaultImagesToTakeByPointOfView = payload
    state.imagesToTakeByPointOfView = payload // NOTE: Side-effect to 'reset' state too
  },
  setIsInformedConsentGiven (state, payload) {
    state.isInformedConsentGiven = payload
  },
  setExplicitClinic (state, payload) {
    state.explicitClinic = payload
  },

  // NOTE: Did not get setStep to work properly. Did not seem to be "reactive"
  //   on the first click, but was on all subsequent clicks. Same was the case
  //   for other setters, this is just the only one where it introduced a bug.
  // setStep (state, payload) {
  //   state.step = payload
  // },
  clear (state) {
    Object.assign(state, initialState)
  },
}

const actions = {
  async populatePresignedUrls ({ commit, dispatch, state }, assessmentUuid) {
    const response = await dispatch('_getPresignedUrls', assessmentUuid)
    const uploadImages = await imageApiServices.parsePresignedUrls({ responseAsJsonApi: response })
    commit('setUploadImages', uploadImages)
    dispatch('_validateImageResponse', uploadImages)
  },

  async _getPresignedUrls (_, assessmentUuid) {
    return imageApiServices.getPresignedUrls({ assessmentUuid: assessmentUuid })
  },

  _validateImageResponse ({ state }, retrievedImageResponse) {
    const expectedImageSlotsReceived = (retrievedImageResponse.length === state.nrOfExpectedImageSlotsToRetrieve)
    if (!expectedImageSlotsReceived) {
      this.errorMessage = 'Something went wrong. Refresh the page, or try another option.'
      this.errorSnackbar = true
      throw new Error('Try another option.')
    }
  },

  async updateWhichImagesToSkip ({ state, commit }) {
    const _uploadImages = JSON.parse(JSON.stringify(state.uploadImages))
    let _iterateImageCountIndex = 0
    let l = 0
    for (l in _uploadImages) {
      const _pointOfView = _uploadImages[l].pointOfView
      let skipped
      let imageCountIndex
      if (
        state.imagesToTakeByPointOfView.includes(_pointOfView) ||
        state.imagesToTakeByPointOfView.includes(state.ASTERIX_WILDCARD)
      ) {
        skipped = false
        _iterateImageCountIndex += 1
        imageCountIndex = _iterateImageCountIndex
      } else {
        skipped = true
        imageCountIndex = null
      }
      _uploadImages[l].skipped = skipped
      _uploadImages[l].imageCountIndex = imageCountIndex
    }
    commit('setUploadImages', _uploadImages)
  },

  async calcImagesToTakeViaUserIntent ({ state, commit, dispatch }) {
    // Step 1: Get point-of-views related to user intent.
    let _uniqueImagesToTakeAsSet = new Set()
    let i = 0
    for (i in state.userResponses.pointOfViews) {
      const _intentUuid = state.userResponses.pointOfViews[i]

      let _intent
      let k = 0
      for (k in state.userIntentOptionsList) {
        if (state.userIntentOptionsList[k].value === _intentUuid) {
          _intent = state.userIntentOptionsList[k]
          commit('setActiveUserIntent', _intent) // Sets the active intent
          break
        }
      }

      let j = 0
      for (j in _intent.imagesToTakeByPointOfView) {
        const imageName = _intent.imagesToTakeByPointOfView[j]
        _uniqueImagesToTakeAsSet.add(imageName)
      }
    }
    const _uniqueImagesToTakeAsArray = [..._uniqueImagesToTakeAsSet]
    commit('setImagesToTakeByPointOfView', _uniqueImagesToTakeAsArray)

    // Step 2: Check if changed to empty state, and if so reset.
    if (state.userResponses.userIntents.length === 0) {
      commit('setActiveUserIntent', {})
      commit('setImagesToTakeByPointOfView', state.defaultImagesToTakeByPointOfView)
    }

    // Step 3: Set non-included images to 'skipped' (current implementation)
    dispatch('updateWhichImagesToSkip')
  },

  isObjectEmpty (obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object
  },
}

export default {
  namespaced: true,
  getters,
  state: { ...initialState },
  actions,
  mutations,
}
