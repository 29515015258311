const dimensionUtils = {
    videoDimensions ({ window, videoToScreenRatio = 0.90, videoWidthToHeightRatio = 1.8 }) {
        const w = window.innerWidth * videoToScreenRatio
        const ratio = videoWidthToHeightRatio
        const h = w / ratio
        return { width: w, height: h }
    },
}

export default dimensionUtils
