const demoImages = {
   // Key -> Point of view
  'bite-front': {
      imageCountIndex: 1,
      precedence: 1,
      demoImageUrl: 'https://static-onsite-toolkit.s3.eu-central-1.amazonaws.com/images/bite_front_detail.jpg',
      title: 'TAB_IMAGE_TITLE_1',
      subheading: 'TAB_SUBHEADING_IMAGE_1',
      subheadingStrong: 'TAB_SUBHEADING_IMAGE_STRONG_1',
      howToVideoId: 'HOW_TO_TAKE_IMAGES_SECTION_VIDEO_LINK_ID_1',
      howToVideoDuration: '0:29',
      successText: 'SUCCESS_PROMPT_ON_IMAGE_CAPTURE_1',
    },
    'bite-left': {
      imageCountIndex: 2,
      precedence: 2,
      demoImageUrl: 'https://static-onsite-toolkit.s3.eu-central-1.amazonaws.com/images/bite_left_detail.jpg',
      title: 'TAB_IMAGE_TITLE_2',
      subheading: 'TAB_SUBHEADING_IMAGE_2',
      subheadingStrong: 'TAB_SUBHEADING_IMAGE_STRONG_2',
      howToVideoId: 'HOW_TO_TAKE_IMAGES_SECTION_VIDEO_LINK_ID_2',
      howToVideoDuration: '0:32',
      successText: 'SUCCESS_PROMPT_ON_IMAGE_CAPTURE_2',
    },
    'bite-right': {
      imageCountIndex: 3,
      precedence: 3,
      demoImageUrl: 'https://static-onsite-toolkit.s3.eu-central-1.amazonaws.com/images/bite_right_detail.jpg',
      title: 'TAB_IMAGE_TITLE_3',
      subheading: 'TAB_SUBHEADING_IMAGE_3',
      subheadingStrong: 'TAB_SUBHEADING_IMAGE_STRONG_3',
      howToVideoId: 'HOW_TO_TAKE_IMAGES_SECTION_VIDEO_LINK_ID_3',
      howToVideoDuration: '0:33',
      successText: 'SUCCESS_PROMPT_ON_IMAGE_CAPTURE_3',
    },
    'upper-arch': {
      imageCountIndex: 4,
      precedence: 4,
      demoImageUrl: 'https://static-onsite-toolkit.s3.eu-central-1.amazonaws.com/images/upper_arch_detail.jpg',
      title: 'TAB_IMAGE_TITLE_4',
      subheading: 'TAB_SUBHEADING_IMAGE_4',
      subheadingStrong: 'TAB_SUBHEADING_IMAGE_STRONG_4',
      howToVideoId: 'HOW_TO_TAKE_IMAGES_SECTION_VIDEO_LINK_ID_4',
      howToVideoDuration: '0:32',
      successText: 'SUCCESS_PROMPT_ON_IMAGE_CAPTURE_4',
    },
    'bottom-arch': {
      imageCountIndex: 5,
      precedence: 5,
      demoImageUrl: 'https://static-onsite-toolkit.s3.eu-central-1.amazonaws.com/images/lower_arch_detail.jpg',
      title: 'TAB_IMAGE_TITLE_5',
      subheading: 'TAB_SUBHEADING_IMAGE_5',
      subheadingStrong: 'TAB_SUBHEADING_IMAGE_STRONG_5',
      howToVideoId: 'HOW_TO_TAKE_IMAGES_SECTION_VIDEO_LINK_ID_5',
      howToVideoDuration: '0:30',
      successText: 'SUCCESS_PROMPT_ON_IMAGE_CAPTURE_5',
    },
  }

export default Object.freeze(demoImages)
