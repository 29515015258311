// IMPORTANT: The aboutImagesToTakeTitle and aboutImagesToTakeSubtitle have
//   been cleaned from the locales. Re-do them if needed again, e.g. might
//   be relevant. See commits from before 22/10/2020 to refined draft texts.

const responseOptions = [
    {
      label: 'USER_RESPONSE_OPTION_TO_INTENT_W_WHITENING',
      value: 'e7c5401a-c703-436c-98b1-cc482098cdd8',
      imagesToTakeByPointOfView: ['bite-front', 'bite-left', 'bite-right', 'upper-arch'],
      popular: true,
    },
    {
      label: 'USER_RESPONSE_OPTION_TO_INTENT_W_ALIGN',
      value: 'bedaf9c6-db16-4dbe-9ded-108b5e8e2036',
      imagesToTakeByPointOfView: ['bite-front', 'bite-left', 'bite-right', 'bottom-arch', 'upper-arch'],
      popular: true,
    },
    {
      label: 'USER_RESPONSE_OPTION_TO_INTENT_W_GENERAL_SMILE_IMPROVEMENT',
      value: '0e86c261-1516-472c-a89d-bf3279e459c2',
      imagesToTakeByPointOfView: ['bite-front', 'bite-left', 'bite-right', 'bottom-arch', 'upper-arch'],
      popular: true,
    },
    {
      label: 'USER_RESPONSE_OPTION_TO_INTENT_W_PAIN',
      value: 'cebd3940-3496-4442-9f1b-d73fa8b77368',
      imagesToTakeByPointOfView: ['bite-front', 'bottom-arch', 'upper-arch'],
    },
    {
      label: 'USER_RESPONSE_OPTION_TO_INTENT_W_GENERAL_CONCERN',
      value: '62d42fbe-deab-410d-a287-a0de87a8f977',
      imagesToTakeByPointOfView: ['bite-front'],
      popular: true,
    },
    {
      label: 'USER_RESPONSE_OPTION_TO_INTENT_W_GENERAL_CHECKUP',
      value: '40b5fd40-c308-4e6e-9f8f-2e6565b9f48a',
      imagesToTakeByPointOfView: ['bite-front', 'bite-left', 'bite-right', 'bottom-arch', 'upper-arch'],
    },
    {
      label: 'USER_RESPONSE_OPTION_TO_INTENT_W_BROKEN_TOOTH',
      value: '5549f025-a97c-44d4-844e-f5762c91689b',
      imagesToTakeByPointOfView: ['bite-front', 'bottom-arch', 'upper-arch'],
    },
    {
      label: 'USER_RESPONSE_OPTION_TO_INTENT_W_IMPLANT',
      value: 'e549d884-e15a-4d96-8186-1cd08e5eebb0',
      imagesToTakeByPointOfView: ['bite-front', 'bite-left', 'bite-right', 'bottom-arch', 'upper-arch'],
    },
    {
      label: 'USER_RESPONSE_OPTION_TO_INTENT_W_RESTORATION',
      value: '15a031d3-2853-41a9-bb15-10ef85e4ef77',
      imagesToTakeByPointOfView: ['bite-left', 'bite-right', 'bite-front', 'bottom-arch', 'upper-arch'],
    },
    {
      label: 'USER_RESPONSE_OPTION_TO_INTENT_W_DENTALFEAR',
      value: '1e6c8166-1271-4b76-b402-61e67b10c095',
      imagesToTakeByPointOfView: ['bite-front', 'bottom-arch', 'upper-arch'],
    },
    // Emergency related (Point-of-Views)
    {
      label: 'USER_RESPONSE_OPTION_TO_INTENT_TEETH_UNSPECIFIED',
      value: 'ce7e7e7f-d10a-4cb8-8932-f849b67350f8',
      imagesToTakeByPointOfView: [], // Used in combination with point of views
    },
    {
      label: 'USER_RESPONSE_OPTION_TO_INTENT_GUMS_UNSPECIFIED',
      value: '1a1a54a8-0c9a-4313-940a-9a40452d1ed1',
      imagesToTakeByPointOfView: [], // As above
    },
    {
      label: 'USER_RESPONSE_OPTION_TO_INTENT_INSIDE_MOUTH',
      value: '15bf79d7-39b6-4ea1-a147-21348e4b9b12',
      imagesToTakeByPointOfView: ['bottom-arch', 'upper-arch'],
    },
    {
      label: 'USER_RESPONSE_OPTION_TO_INTENT_ON_TEETH',
      value: 'ab8665be-8f5b-4dd1-b308-639204155cd9',
      imagesToTakeByPointOfView: ['bite-front'],
    },
    {
      label: 'USER_RESPONSE_OPTION_TO_INTENT_BOTTOM_ARCH',
      value: '2ef548a6-6f31-4ccc-861d-224976c52ddd',
      imagesToTakeByPointOfView: ['bottom-arch'],
    },
    {
      label: 'USER_RESPONSE_OPTION_TO_INTENT_UPPER_ARCH',
      value: '0c7f325c-d963-42a6-8da6-d59bcd260dce',
      imagesToTakeByPointOfView: ['upper-arch'],
    },
    {
      label: 'USER_RESPONSE_OPTION_TO_INTENT_FROM_THE_SIDE',
      value: '4c7b671e-f6bf-4e36-a9bc-8a8e349fb4e7',
      imagesToTakeByPointOfView: ['bite-left', 'bite-right'],
    },
    {
      label: 'USER_RESPONSE_OPTION_TO_INTENT_BITE_FRONT',
      value: 'ee39de96-c995-428f-b607-6eea2c3dd6ad',
      imagesToTakeByPointOfView: ['bite-front'],
    },
    {
      label: 'USER_RESPONSE_OPTION_TO_INTENT_UPPER_ARCH_RIGHT_SIDE',
      value: '585b854c-589f-4d32-a573-a3ec05b66cd5',
      imagesToTakeByPointOfView: ['upper-arch', 'bite-right'],
    },
    {
      label: 'USER_RESPONSE_OPTION_TO_INTENT_UPPER_ARCH_LEFT_SIDE',
      value: 'f028bcee-e52e-445d-9376-f76260147272',
      imagesToTakeByPointOfView: ['upper-arch', 'bite-left'],
    },
    {
      label: 'USER_RESPONSE_OPTION_TO_INTENT_BOTTOM_ARCH_RIGHT_SIDE',
      value: '28d099a6-40ee-47bb-810f-218a68276960',
      imagesToTakeByPointOfView: ['bottom-arch', 'bite-right'],
    },
    {
      label: 'USER_RESPONSE_OPTION_TO_INTENT_BOTTOM_ARCH_LEFT_SIDE',
      value: '5a2da1e8-ef37-4537-8214-fd944426daa7',
      imagesToTakeByPointOfView: ['bottom-arch', 'bite-left'],
    },
    {
      label: 'USER_RESPONSE_OPTION_TO_INTENT_DONT_KNOW_POINT_OF_VIEW',
      value: '68b3b940-1c24-4395-86cd-700e956106f2',
      imagesToTakeByPointOfView: ['bottom-arch', 'upper-arch'],
    },
    // Default "other"
    {
      label: 'USER_RESPONSE_OPTION_TO_INTENT_DEFAULT_OTHER',
      value: 'd6fd4491-36bb-4263-8981-f9b944e3f92e',
      imagesToTakeByPointOfView: ['bite-front', 'bite-left', 'bite-right', 'bottom-arch', 'upper-arch'],
      default: true,
    },
]

export default Object.freeze(responseOptions)
