const analyticsLoader = {
    src (appId) {
        const scriptTemplate = `
        https://www.googletagmanager.com/gtag/js?id=${appId}
        `
        return scriptTemplate
    },
    init (appId) {
      const scriptTemplate = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${appId}');
      `
      return scriptTemplate
  },
}

export default analyticsLoader
