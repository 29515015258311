const responseOptions = [
    {
      label: 'USER_RESPONSE_OPTION_TO_PRIORITIES_W_QUALITY',
      value: 'quality-of-care',
    },
    {
      label: 'USER_RESPONSE_OPTION_TO_PRIORITIES_W_AFFORDABILITY',
      value: 'affordability',
    },
    {
      label: 'USER_RESPONSE_OPTION_TO_PRIORITIES_W_LENGTH_OF_TREATMENT',
      value: 'length-of-treatment',
    },
    {
      label: 'USER_RESPONSE_OPTION_TO_PRIORITIES_W_CONVENIENCE',
      value: 'convenience',
    },
]

export default Object.freeze(responseOptions)
