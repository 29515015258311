import DemoImages from '@/views/widgets/data/DemoImages'

function keysrt (key) {
  return function (a, b) {
   if (a[key] > b[key]) return 1
   if (a[key] < b[key]) return -1
   return 0
  }
}

var parserUtils = {
  parseImageSlotsToUploadImageResources (imageSlotsData, predefinedImageset = DemoImages) {
    const _uploadImageResources = []

    let i = 0
    for (i in imageSlotsData) {
      const imageSlot = imageSlotsData[i]
      const pointOfView = imageSlot.attributes.point_of_view
      const predefinedImage = predefinedImageset[pointOfView]

      const url = imageSlot.attributes.url
      const imageName = imageSlot.attributes.name
      _uploadImageResources.push({
        url: url,
        imageName: imageName,
        pointOfView: pointOfView,
        file: null,
        displayableImageUrl: null,
        skipped: false,
        uploaded: false,
        title: predefinedImage.title,
        precedence: predefinedImage.precedence,
        demoImageUrl: predefinedImage.demoImageUrl,
        subheading: predefinedImage.subheading,
        subheadingStrong: predefinedImage.subheadingStrong,
        howToVideoId: predefinedImage.howToVideoId,
        howToVideoDuration: predefinedImage.howToVideoDuration,
        successText: predefinedImage.successText,
      })
    }
    _uploadImageResources.sort(keysrt('precedence'))
    return _uploadImageResources
  },
  parsePrepopulatedLastestStateAssessmentResource (assessmentData) {
    const latestState = assessmentData.attributes.latest_funnel_widget_state_as_json
    const payload = {
      previousAssessmentUuid: latestState.assessmentUuid || '',
      userIntents: latestState.userIntents || [],
      email: latestState.email || '',
      phone: latestState.phone || '',
      postalCode: latestState.postalCode || '',
      year: latestState.year || '',
      name: latestState.name || '',
      primaryGoal: latestState.primaryGoal || '',
    }
    return payload
  },
}

export default parserUtils
