<template>
  <div>
    <validation-observer v-slot="{ valid }">
      <funnel-widget-container
        v-model="step"
        v-scroll="onScroll"
        :active-step="step"
        :steps="stepsAsFlatList"
        :available-steps="availableSteps"
        :is-loading="isLoading"
        :header-height="headerHeight"
        :show-avatar-toolbar="showAvatarToolbar"
        :show-close-button="showCloseButton"
        :is-bottom-toolbar-visible="showComponent0"
        :is-submitted="isSubmitted"
        :nr-images-to-take="nrImagesToTake"
        :image-count-index="displayedImageCountIndex"
        class="mx-auto"
        @click:next="next(valid)"
        @click:prev="stepBack()"
        @change="checkChangeAnticipated(step)"
        @continue-from-first-screen="trackContinueFromFirstScreen()"
      >
        <v-tab-item class="pb-4">
          <desktop-banner
            v-if="!isMobile()"
          />

          <v-row
            :style="`opacity: ${headerTextOpacity};`"
          >
            <v-col
              cols="12"
              md="12"
            >
              <v-slide-x-transition>
                <div
                  v-show="showComponent0 && !welcomeTextExplicitlyHidden"
                  class="mb-12"
                  style="color: white;"
                >
                  <v-card-title class="mb-0">
                    {{ $t('WELCOME_TO_WIDGET_TOP_LEVEL_GREETING') }} 👋
                  </v-card-title>
                  <v-card-text>
                    <div class="subtitle-1">
                      {{ $t('WELCOME_TO_WIDGET_SUBTITLE_GREETING') }}
                    </div>
                  </v-card-text>
                </div>
              </v-slide-x-transition>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="11">
              <v-fade-transition>
                <v-card
                  v-show="showComponent1"
                  class="mt-n12"
                >
                  <v-progress-linear
                    absolute
                    top
                    height="2"
                    color="primary"
                  />

                  <v-card-title class="widget-card-title">
                    {{ $t('TAB_HEADING_HOW_IT_WORKS') }}
                  </v-card-title>
                  <v-card-text>
                    <p>
                      <v-icon eager>
                        mdi-check
                      </v-icon> {{ $t('HOW_IT_WORKS_CARD_STEP_1') }}
                    </p>
                    <p>
                      <v-icon eager>
                        mdi-check
                      </v-icon> {{ $t('HOW_IT_WORKS_CARD_STEP_2') }}
                    </p>
                    <p>
                      <v-icon eager>
                        mdi-check
                      </v-icon> {{ $t('HOW_IT_WORKS_CARD_STEP_3') }}
                    </p>
                  </v-card-text>

                  <v-divider class="mx-4" />

                  <v-card-actions>
                    <v-avatar class="ml-3">
                      <v-img
                        :src="$t('PROVIDER_DENTIST_PORTRAIT_URL')"
                        :lazy-src="$t('PLACEHOLDER_PROVIDER_DENTIST_PORTRAIT_URL')"
                      />
                    </v-avatar>
                    <v-card-text>
                      {{ $t('PROVIDER_DENTIST_NAME_WITH_TITLE') }}
                    </v-card-text>
                    <v-spacer />
                    <v-btn
                      icon
                      @click="showMoreAboutHowItWorks = !showMoreAboutHowItWorks"
                    >
                      <v-icon>{{ showMoreAboutHowItWorks ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                    </v-btn>
                  </v-card-actions>
                  <v-expand-transition>
                    <div v-show="showMoreAboutHowItWorks">
                      <v-divider />

                      <v-card-text>
                        {{ $t('HOW_IT_WORKS_EXTENDED_DESCRIPTION') }}
                      </v-card-text>
                    </div>
                  </v-expand-transition>
                </v-card>
              </v-fade-transition>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <share-link-by-email-card
                v-if="!isMobile() && showComponent3"
                :assessment-uuid="assessmentUuid"
                :card-title="$t('LOOKS_LIKE_YOU_ARE_ON_DESKTOP_BY_EMAIL_CARD_TITLE')"
                :card-subtitle="$t('LOOKS_LIKE_YOU_ARE_ON_DESKTOP_BY_EMAIL_CARD_SUBTITLE')"
                :widget-uuid="widgetUuid"
              />
            </v-col>
          </v-row>

          <video-or-image-or-none v-if="includeWhenIsItGoodCard" />
        </v-tab-item>

        <v-tab-item class="pb-4">
          <!-- TODO: Extract tab into @/views/widgets/components/tabs/BaseInfo.vue -->
          <div
            class="widget-display-2 font-weight-light mt-6"
            :style="`color: rgb(0, 0, 0, 0.5); opacity: ${headerTextOpacity}`"
          >
            <strong>{{ $t('STEP_1_OUT_OF_2') }}</strong> {{ $t('TAB_HEADING_HOW_CAN_WE_HELP') }}
          </div>
          <v-row v-show="includeFunnelInformation1Cards">
            <v-col cols="12">
              <!-- IMPORTANT!!: The funnelInformation1ShowSocialSecurityInfoCard
                    is a breaking change on `showSocialSecurityInfoCard`. Remove
                    the "or" statement when fully migrated on clinics.
              -->
              <funnel-information
                v-if="loadLazyComponents && includeFunnelInformation1Cards"
                :screen-identifier="'dedicated-question-page'"
                :widget-type="widgetType"
                :show-base-info-card="funnelInformation1ShowBaseInfoCard"
                :show-primary-goal-card="funnelInformation1ShowPrimaryGoalCard"
                :show-user-intents-checklist-card="funnelInformation1ShowUserIntentsChecklistCard"
                :show-social-security-info-card="funnelInformation1ShowSocialSecurityInfoCard || showSocialSecurityInfoCard"
                :show-clinic-locations-card="funnelInformation1ShowClinicLocationsCard"
                :show-point-of-view-checklist-card="funnelInformation1ShowPointOfViewChecklistCard"
                :nr-images-to-take="nrImagesToTake"
                :assessment-uuid="assessmentUuid"
                @update:response="updateResponse"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-card>
                <v-progress-linear
                  absolute
                  top
                  height="2"
                  color="primary"
                />
                <v-card-text>
                  <v-row>
                    <v-col cols="2">
                      <v-img
                        class="mt-2"
                        src="https://static-onsite-toolkit.s3.eu-central-1.amazonaws.com/images/ce-mark.png"
                      />
                    </v-col>
                    <v-col cols="10">
                      {{ $t('CE_MARK_CARD_TEXT') }}
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>

        <!-- Image overview -->

        <v-tab-item class="pb-4">
          <div
            class="widget-display-2 font-weight-light mt-6"
            :style="`color: rgb(0, 0, 0, 0.5); opacity: ${headerTextOpacity}`"
          >
            <strong>{{ $t('STEP_2_OUT_OF_2') }}</strong> {{ $t('TAB_HEADING_IMAGE_OVERVIEW') }}
          </div>
          <image-overview
            v-if="loadLazyComponents"
            :header-text-opacity="headerTextOpacity"
            :overview-images="computedOverviewImages"
            :images-to-take-by-point-of-view="imagesToTakeByPointOfView"
          />
        </v-tab-item>

        <!-- Image 1 -->

        <v-tab-item class="pb-4">
          <image-capture
            v-if="loadLazyComponents"
            :upload-image="uploadImages[0]"
            @click:next="next(true)"
            @onImageInputChange="onImageInputChange"
          />
        </v-tab-item>

        <!-- Image 2 -->

        <v-tab-item class="pb-4">
          <image-capture
            v-if="loadLazyComponents"
            :upload-image="uploadImages[1]"
            @click:next="next(true)"
            @onImageInputChange="onImageInputChange"
          />
        </v-tab-item>

        <!-- Image 3 -->

        <v-tab-item class="pb-4">
          <image-capture
            v-if="loadLazyComponents"
            :upload-image="uploadImages[2]"
            @click:next="next(true)"
            @onImageInputChange="onImageInputChange"
          />
        </v-tab-item>

        <!-- Image 4 -->

        <v-tab-item class="pb-4">
          <image-capture
            v-if="loadLazyComponents"
            :upload-image="uploadImages[3]"
            @click:next="next(true)"
            @onImageInputChange="onImageInputChange"
          />
        </v-tab-item>

        <!-- Image 5 -->

        <v-tab-item class="pb-4">
          <image-capture
            v-if="loadLazyComponents"
            :upload-image="uploadImages[4]"
            @click:next="next(true)"
            @onImageInputChange="onImageInputChange"
          />
        </v-tab-item>

        <!--/ End of images -->

        <v-tab-item class="pb-4">
          <!-- TODO: Extract tab into @/views/widgets/components/tabs/ConfirmSubmit.vue -->
          <v-row>
            <v-col cols="12">
              <v-card flat>
                <v-card-text>
                  <div class="widget-display-2 mb-6 mt-6">
                    <v-icon x-large>
                      mdi-checkbox-marked-circle-outline
                    </v-icon>
                  </div>
                  <div class="widget-display-2 font-weight-light">
                    {{ $t('TAB_HEADING_FINAL_SUBMIT_TEXT') }}
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row v-show="includeFunnelInformation2Cards">
            <v-col cols="12">
              <funnel-information
                v-if="loadLazyComponents"
                :screen-identifier="'questions-before-final-submit'"
                :widget-type="widgetType"
                :show-base-info-card="funnelInformation2ShowBaseInfoCard"
                :show-primary-goal-card="funnelInformation2ShowPrimaryGoalCard"
                :show-user-intents-checklist-card="funnelInformation2ShowUserIntentsChecklistCard"
                :show-social-security-info-card="funnelInformation2ShowSocialSecurityInfoCard"
                :show-clinic-locations-card="funnelInformation1ShowClinicLocationsCard"
                :show-point-of-view-checklist-card="funnelInformation2ShowPointOfViewChecklistCard"
                :nr-images-to-take="nrImagesToTake"
                :assessment-uuid="assessmentUuid"
                @update:response="updateResponse"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-card
                flat
                :class="`${includeFunnelInformation2Cards ? 'mt-6' : ''}`"
              >
                <v-card-text>
                  <div>
                    <p>{{ $t('INFORMED_CONSENT_WHY_TEXT') }}</p>
                    <v-checkbox
                      :label="informedConsentAction.label"
                      @change="updateIsInformedConsentGiven"
                    />

                    <p>
                      {{ $t('WRAPPER_TEXT_FOR_LEGAL_LINKS_PART_1') }}
                      <a
                        href="#"
                        class="mr-0 grey--text text--darken-3"
                        @click="iframeDialog = true, iframeLoading = true, calculateIframeHeight(), loadLinkInIframe(privacyPolicyReference.link)"
                        v-text="privacyPolicyReference.text"
                      />
                      {{ $t('WRAPPER_TEXT_FOR_LEGAL_LINKS_PART_2') }}
                      <a
                        href="#"
                        class="mr-0 grey--text text--darken-3"
                        @click="iframeDialog = true, iframeLoading = true, calculateIframeHeight(), loadLinkInIframe(informedConsentReference.link)"
                        v-text="informedConsentReference.text"
                      />
                      {{ $t('WRAPPER_TEXT_FOR_LEGAL_LINKS_PART_3') }}
                    </p>
                    <p class="grey--text text--darken-2 mt-10">
                      {{ $t('POWERED_BY_ADENT_HEALTH_TEXT_1') }}<v-icon>mdi-camera</v-icon>{{ $t('POWERED_BY_ADENT_HEALTH_TEXT_2') }}
                    </p>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item class="pb-4">
          <!-- TODO: Extract tab into @/views/widgets/components/tabs/FormFinished.vue -->
          <v-card flat>
            <v-card-text>
              <div
                class="widget-display-2 font-weight-light mb-6 mt-6"
              >
                {{ $t('TAB_HEADING_FINISH_PAGE') }}
              </div>

              <div
                class="font-weight-light mb-6 mt-12"
              >
                {{ $t('SHARE_WITH_OTHER_NATIVE_SHARE_LINK_HEADING') }}
              </div>
            </v-card-text>
          </v-card>

          <v-row>
            <v-col>
              <v-card>
                <v-progress-linear
                  absolute
                  top
                  height="2"
                  color="primary"
                />
                <v-card-title>
                  {{ $t('SHARE_WITH_OTHER_CARD_TITLE') }}
                </v-card-title>

                <v-card-subtitle>
                  {{ $t('SHARE_WITH_OTHER_CARD_SUBTITLE') }}
                </v-card-subtitle>

                <v-divider />

                <v-card-text>
                  <v-row>
                    <v-col
                      cols="4"
                      class="text-center"
                    >
                      <v-icon class="mb-2">
                        mdi-numeric-1
                      </v-icon>
                      <div class="caption">
                        {{ $t('SHARE_WITH_OTHER_STEP_1_TEXT') }}
                      </div>
                    </v-col>
                    <v-col
                      cols="4"
                      class="text-center"
                    >
                      <v-icon class="mb-2">
                        mdi-numeric-2
                      </v-icon>
                      <div class="caption">
                        {{ $t('SHARE_WITH_OTHER_STEP_2_TEXT') }}
                      </div>
                    </v-col>
                    <v-col
                      cols="4"
                      class="text-center"
                    >
                      <v-icon class="mb-2">
                        mdi-numeric-3
                      </v-icon>
                      <div class="caption">
                        {{ $t('SHARE_WITH_OTHER_STEP_3_TEXT') }}
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-divider />

                <v-card-actions>
                  <v-btn
                    color="grey"
                    text
                    @click="copyWidgetLinkToClipboard()"
                  >
                    {{ $t('SHARE_WITH_OTHER_COPY_RAW_LINK_TEXT') }}
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    v-show="isNativeShareSupported"
                    min-width="100"
                    color="primary"
                    @click="shareLinkNativeShare()"
                  >
                    {{ $t('SHARE_WITH_OTHER_SHARE_LINK_WITH_NATIVE_SHARE_TEXT') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-dialog
          v-model="iframeDialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar
              color="grey lighten-3"
            >
              <v-progress-linear
                :active="true"
                :indeterminate="iframeLoading"
                absolute
                bottom
                color="primary"
              />
              <v-btn
                icon
                light
                @click="iframeDialog = false"
              >
                <v-icon>mdi-arrow-collapse-down</v-icon>
              </v-btn>
            </v-toolbar>
            <div
              id="frame-sheet"
              ref="frameSheet"
            >
              <v-sheet
                :height="iframeHeight"
              >
                <iframe
                  width="100%"
                  height="100%"
                  frameborder="0"
                  :src="activeIframeUrl"
                />
              </v-sheet>
            </div>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="showCompressImageDialog"
          width="80%"
          max-width="400"
        >
          <v-card>
            <v-card-title class="mb-1">
              {{ $t('CONNECTION_ISSUE_DETECTED_TITLE') }}
            </v-card-title>

            <v-card-text>
              <p>
                {{ $t('CONNECTION_ISSUE_DETECTED_TEXT') }}
              </p>
            </v-card-text>

            <v-divider />

            <v-card-actions>
              <v-btn
                color="grey"
                text
                @click="isRetryUpload = true, showCompressImageDialog = false, onImageInputChange()"
              >
                {{ $t('CONNECTION_ISSUE_DETECTED_DISMISS_BUTTON_TEXT') }}
              </v-btn>
              <v-spacer />
              <v-btn
                color="primary"
                text
                @click="compressImagesOnUpload = true, isRetryUpload = true, showCompressImageDialog = false, onImageInputChange()"
              >
                {{ $t('CONNECTION_ISSUE_DETECTED_ACCEPT_BUTTON_TEXT') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="showFailedCompressedUploadDialog"
          width="80%"
          max-width="400"
        >
          <v-card>
            <v-card-title class="mb-1">
              {{ $t('CONNECTION_ISSUE_UPLOAD_FAILED_AFTER_COMPRESS_TITLE') }}
            </v-card-title>

            <v-card-text>
              <p>
                {{ $t('CONNECTION_ISSUE_UPLOAD_FAILED_AFTER_COMPRESS_TEXT_PT1') }}
                {{ imageCompression }}%
                {{ $t('CONNECTION_ISSUE_UPLOAD_FAILED_AFTER_COMPRESS_TEXT_PT2') }}
              </p>
              <p
                v-show="isLinkShared"
                class="success--text"
              >
                {{ $t('NATIVE_SHARE_SUCCESS_TEXT') }}
              </p>
            </v-card-text>

            <v-divider />

            <v-card-actions>
              <v-btn
                color="grey"
                text
                @click="isRetryUpload = true, showFailedCompressedUploadDialog = false, onImageInputChange()"
              >
                {{ $t('CONNECTION_ISSUE_UPLOAD_FAILED_AFTER_COMPRESS_TRY_AGAIN_BUTTON_TEXT') }}
              </v-btn>
              <v-spacer />
              <v-btn
                v-show="isNativeShareSupported"
                min-width="100"
                color="primary"
                @click="shareLinkNativeShare()"
              >
                {{ $t('CONNECTION_ISSUE_UPLOAD_FAILED_AFTER_COMPRESS_COPY_LINK_BUTTON_TEXT') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-snackbar
          v-model="primarySnackbar"
          color="primary"
          top
          center
        >
          {{ primarySnackbarText }}
        </v-snackbar>
        <v-snackbar
          v-model="errorSnackbar"
          color="error"
          top
          center
        >
          <span class="font-weight-bold">&nbsp;{{ errorMessage }}&nbsp;</span> {{ errorTraceback }}
        </v-snackbar>

        <v-dialog
          v-model="didYouSlideDialog"
          max-width="290"
        >
          <v-card>
            <v-card-title class="headline">
              {{ $t('DID_YOU_SLIDE_DIALOG_TITLE_TEXT') }}
            </v-card-title>

            <v-card-text>
              {{ $t('DID_YOU_SLIDE_DIALOG_BODY_TEXT') }}
            </v-card-text>

            <v-card-actions>
              <v-spacer />

              <v-btn
                color="success darken-1"
                text
                @click="stepBack(), didYouSlideDialog = false"
              >
                {{ $t('DID_YOU_SLIDE_DIALOG_GO_BACK_BUTTON_TEXT') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="imagesMissingDialog"
          max-width="290"
          width="90%"
          persistent
        >
          <v-card>
            <v-card-title class="headline">
              {{ $t('REQUIRED_IMAGES_MISSING_CARD_TITLE') }}
            </v-card-title>

            <v-card-text>
              <p>
                {{ $t('REQUIRED_IMAGES_MISSING_CARD_TEXT_PT1') }}
                {{ nrImagesTaken }}
                {{ $t('REQUIRED_IMAGES_MISSING_CARD_TEXT_PT2') }}
                {{ nrImagesToTake }}
                {{ $t('REQUIRED_IMAGES_MISSING_CARD_TEXT_PT3') }}
              </p>
              <!-- NOTE: Less than, `<`. Differs from all similar below. -->
              <p v-show="nrImagesTaken < minNrImagesToTake">
                {{ $t('REQUIRED_IMAGES_MISSING_MINIMUM_NR_CARD_TEXT_PT1') }}
                {{ minNrImagesToTake }}.
              </p>
              <p v-show="nrImagesTaken >= minNrImagesToTake">
                {{ $t('REQUIRED_IMAGES_MISSING_FEEDBACK_CARD_TEXT') }}
              </p>
              <v-textarea
                v-show="nrImagesTaken >= minNrImagesToTake"
                v-model="internalUserResponses.feedback"
                filled
                @change="updateFeedback"
              />
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="grey lighten-1"
                text
                @click="imagesMissingDialog = false"
              >
                {{ $t('REQUIRED_IMAGES_MISSING_DISMISS_BUTTON_TEXT') }}
              </v-btn>
              <v-spacer />

              <v-btn
                v-show="nrImagesTaken >= minNrImagesToTake"
                color="success darken-1"
                text
                @click="next(), imagesMissingDialog = false"
              >
                {{ $t('REQUIRED_IMAGES_MISSING_SUBMIT_BUTTON_TEXT') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="requiredInformationIsMissingDialog"
          max-width="290"
          width="90%"
          persistent
        >
          <v-card>
            <v-card-title class="headline">
              {{ $t('REQUIRED_INFORMATION_MISSING_CARD_TITLE') }}
            </v-card-title>

            <v-card-text>
              <p>
                {{ $t('REQUIRED_INFORMATION_MISSING_CARD_TEXT') }}
              </p>
              <v-text-field
                v-model="internalUserResponses.email"
                :label="$t('REQUIRED_INFORMATION_MISSING_CARD_EMAIL_LABEL')"
              />
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="grey lighten-1"
                text
                @click="requiredInformationIsMissingDialog = false"
              >
                {{ $t('REQUIRED_INFORMATION_MISSING_DISMISS_BUTTON_TEXT') }}
              </v-btn>
              <v-spacer />

              <v-btn
                color="success darken-1"
                text
                @click="setEmailAndSubmit(), requiredInformationIsMissingDialog = false"
              >
                {{ $t('REQUIRED_INFORMATION_MISSING_SUBMIT_BUTTON_TEXT') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </funnel-widget-container>
    </validation-observer>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import Store from './FunnelWidget.store'

  import submitApiServices from '@/views/widgets/services/submit_endpoints'
  import imageApiServices from '@/views/widgets/services/image_endpoints'
  import validationApiServices from '@/views/widgets/services/validation_endpoints'
  import analyticsEndpoints from '@/views/widgets/services/analytics_endpoints'
  import parserUtils from '@/views/widgets/services/parser_utils'
  import widgetUrlServices from '@/views/widgets/services/widget_url_utils'
  import colorUtils from '@/views/widgets/services/color_utils'
  import ImageTools from '@/views/widgets/services/image_resize'

  import { loadLanguageAsync } from '@/i18n'
  import pixelLoader from '@/external/facebook_pixel'
  import hotjarLoader from '@/external/hotjar_analytics'
  import analyticsLoader from '@/external/google_analytics'
  import autopilotLoader from '@/external/autopilot_analytics'
  import airbrake from '@/plugins/airbrake'

  import FunnelWidgetContainer from '@/views/widgets/funnel_widget/components/stateful/FunnelWidgetContainer'
  import ShareLinkByEmailCard from '@/views/widgets/components/ShareLinkByEmailCard'
  import DesktopBanner from '@/views/widgets/funnel_widget/components/DesktopBanner'
  import VideoOrImageOrNone from '@/views/widgets/funnel_widget/components/VideoOrImageOrNone'
  // Dynamic imports
  const ImageCapture = () => import('@/views/widgets/funnel_widget/components/ImageCapture.vue')
  const ImageOverview = () => import('@/views/widgets/funnel_widget/components/ImageOverview.vue')
  const FunnelInformation = () => import('@/views/widgets/funnel_widget/components/stateful/FunnelInformation.vue')

  export default {
    name: 'FunnelWidget',

    components: {
      FunnelWidgetContainer,
      ImageCapture,
      ImageOverview,
      ShareLinkByEmailCard,
      FunnelInformation,
      DesktopBanner,
      VideoOrImageOrNone,
    },

    data: () => ({
      // Other inputs
      widgetUuid: '',
      assessmentUuid: '',
      topicOfInterestUuid: '',
      assessmentTypeUuid: '51722b18-9abc-4070-8978-3166c1a18fee', // Remote Dental Care
      shareEmail: '',
      sessionId: '',
      widgetType: '',
      landingPageType: '',

      // Temporary state
      internalUserResponses: {
        email: '',
        feedback: '',
      },

      // State
      // NOTE: 'step' was 2020/11/15 attempted pulled into Vuex with a
      //   getter (`currentPage/step`) and a setter (currentPage/setStep),
      //   but without succes. There was a bug that the setter did not
      //   set the passed in payload on FIRST click, but did on subsequent
      //   clicks it did. Cause was not found.
      step: 0,
      image: null,
      isLoading: false,
      isSubmitted: false,
      primarySnackbar: false,
      primarySnackbarText: '',
      errorSnackbar: false,
      errorMessage: '',
      errorTraceback: '',
      isConfirmationEmailSent: false,
      isWidgetInitializedRecordedOnAnalytics: false,
      isUserIntentRecordedOnAnalytics: false,
      isBaseInfoGivenRecordedOnAnalytics: false,
      isAdditionalInfoRecordedOnAnalytics: false,
      trafficSource: '',
      activeUserIntent: {},
      didYouSlideDialog: false,
      iframeDialog: false,
      iframeLoading: false,
      iframeHeight: 0,
      activeIframeUrl: '',
      offsetTop: 0,
      headerHeight: 255,
      defaultHeaderHeightInitialScreen: 255,
      defaultHeaderHeightDuringFlow: 100,
      showAvatarToolbar: false,
      showMoreAboutHowItWorks: false,
      showImageCaptureAbout: false,
      showSignOfNrImagesToTakeHasChanged: false,
      showComponent0: false,
      showComponent1: false,
      showComponent2: false,
      showComponent3: false,
      showComponent4: false,
      welcomeTextExplicitlyHidden: false, // HACK: If user slides back to first screen, it is not registered by `next`. This prevents a wierd layout.
      isLinkShared: false,
      showCloseButton: true,
      loadLazyComponents: true,
      imagesMissingDialog: false,
      imagesMissingDialogAlreadyPrompted: false,
      requiredInformationIsMissingDialog: false,
      nrImagesTaken: null,

      // Compress images
      isLowBandwidthDetected: false,
      compressImagesOnUpload: false,
      showCompressImageDialog: false,
      showFailedCompressedUploadDialog: false,
      imageSizeRaw: 0,
      imageSizeCompressed: 1,
      isRetryUpload: false,

      // Images and questions
      defaultNrImagesToTake: 5,
      questionsToAnswer: [], // TODO: Not implemented yet.

      // Static
      demoVideoYoutubeId: '',

      // Analytics
      pixelLookupKey: 'PROVIDER_FACEBOOK_PIXEL_ID',
      analyticsLookupKey: 'PROVIDER_SPECIFIC_ADENT_GOOGLE_ANALYTICS_ID',
    }),

    computed: {
      // TODO: Extract things into Vuex, note from 2020/9/24.
      ...mapGetters({
        userResponses: 'currentPage/userResponses',
        uploadImages: 'currentPage/uploadImages',
        availableSteps: 'currentPage/availableSteps',

        // Secondary stuff
        enumSteps: 'currentPage/enumSteps',
        overviewImages: 'currentPage/overviewImages',
        userIntentOptionsList: 'currentPage/userIntentOptionsList',
        imageFunnelStartIndex: 'currentPage/imageFunnelStartIndex',
        maxNrOfImagesToUpload: 'currentPage/maxNrOfImagesToUpload',
        imagesToTakeByPointOfView: 'currentPage/imagesToTakeByPointOfView',
        imageStepRange: 'currentPage/imageStepRange',
        explicitClinic: 'currentPage/explicitClinic',
        ASTERIX_WILDCARD: 'currentPage/ASTERIX_WILDCARD',
        isInformedConsentGiven: 'currentPage/isInformedConsentGiven',
      }),

      minNrImagesToTake () {
        return Number(this.$t('MINIMUM_NR_IMAGES_TO_TAKE'))
      },

      // Legal documents and consent
      informedConsentAction () {
        return {
          label: this.$t('INFORMED_CONTENT_CHECKBOX_TEXT'),
          value: false,
        }
      },
      privacyPolicyReference () {
        return {
          text: this.$t('ADENT_PRIVACY_POLICY_TEXT'),
          link: this.$t('ADENT_PRIVACY_POLICY_LINK'),
        }
      },
      informedConsentReference () {
        return {
          text: this.$t('ADENT_INFORMED_CONSENT_AGREEMENT_TEXT'),
          link: this.$t('ADENT_INFORMED_CONSENT_AGREEMENT_LINK'),
        }
      },
      includeWhenIsItGoodCard () {
        const lookup = 'INCLUDE_WHEN_IS_IT_GOOD_CARD'
        const defaultValue = false
        return this.jsonifyTrueOrFalseOrDefault({ lookup, defaultValue })
      },

      includeFunnelInformation1Cards () {
        const lookup = 'INCLUDE_FUNNEL_INFORMATION_1_CARDS'
        const defaultValue = false
        return this.jsonifyTrueOrFalseOrDefault({ lookup, defaultValue })
      },
      funnelInformation1ShowBaseInfoCard () {
        const lookup = 'FUNNEL_INFORMATION_1_SHOW_BASE_INFO_CARD'
        const defaultValue = false
        return this.jsonifyTrueOrFalseOrDefault({ lookup, defaultValue })
      },
      funnelInformation1ShowPrimaryGoalCard () {
        const lookup = 'FUNNEL_INFORMATION_1_SHOW_PRIMARY_GOAL_CARD'
        const defaultValue = false
        return this.jsonifyTrueOrFalseOrDefault({ lookup, defaultValue })
      },
      funnelInformation1ShowUserIntentsChecklistCard () {
        const lookup = 'FUNNEL_INFORMATION_1_SHOW_USER_INTENTS_CHECKLIST_CARD'
        const defaultValue = false
        return this.jsonifyTrueOrFalseOrDefault({ lookup, defaultValue })
      },
      funnelInformation1ShowSocialSecurityInfoCard () {
        const lookup = 'FUNNEL_INFORMATION_1_SHOW_SOCIAL_SECURITY_INFO_CARD'
        const defaultValue = false
        return this.jsonifyTrueOrFalseOrDefault({ lookup, defaultValue })
      },
      funnelInformation1ShowClinicLocationsCard () {
        const lookup = 'FUNNEL_INFORMATION_1_SHOW_CLINIC_LOCATIONS_CARD'
        const defaultValue = false
        return this.jsonifyTrueOrFalseOrDefault({ lookup, defaultValue })
      },
      includeFunnelInformation2Cards () {
        const lookup = 'INCLUDE_FUNNEL_INFORMATION_2_CARDS'
        const defaultValue = false
        return this.jsonifyTrueOrFalseOrDefault({ lookup, defaultValue })
      },
      funnelInformation2ShowBaseInfoCard () {
        const lookup = 'FUNNEL_INFORMATION_2_SHOW_BASE_INFO_CARD'
        const defaultValue = false
        return this.jsonifyTrueOrFalseOrDefault({ lookup, defaultValue })
      },
      funnelInformation2ShowPrimaryGoalCard () {
        const lookup = 'FUNNEL_INFORMATION_2_SHOW_PRIMARY_GOAL_CARD'
        const defaultValue = false
        return this.jsonifyTrueOrFalseOrDefault({ lookup, defaultValue })
      },
      funnelInformation2ShowUserIntentsChecklistCard () {
        const lookup = 'FUNNEL_INFORMATION_2_SHOW_USER_INTENTS_CHECKLIST_CARD'
        const defaultValue = false
        return this.jsonifyTrueOrFalseOrDefault({ lookup, defaultValue })
      },
      funnelInformation2ShowSocialSecurityInfoCard () {
        const lookup = 'FUNNEL_INFORMATION_2_SHOW_SOCIAL_SECURITY_INFO_CARD'
        const defaultValue = false
        return this.jsonifyTrueOrFalseOrDefault({ lookup, defaultValue })
      },
      funnelInformation2ShowClinicLocationsCardVisible () {
        const lookup = 'FUNNEL_INFORMATION_2_SHOW_CLINIC_LOCATIONS_CARD'
        const defaultValue = false
        return this.jsonifyTrueOrFalseOrDefault({ lookup, defaultValue })
      },
      funnelInformation1ShowPointOfViewChecklistCard () {
        const lookup = 'FUNNEL_INFORMATION_1_SHOW_POINT_OF_VIEW_CARD'
        const defaultValue = false
        return this.jsonifyTrueOrFalseOrDefault({ lookup, defaultValue })
      },
      funnelInformation2ShowPointOfViewChecklistCard () {
        const lookup = 'FUNNEL_INFORMATION_2_SHOW_POINT_OF_VIEW_CARD'
        const defaultValue = false
        return this.jsonifyTrueOrFalseOrDefault({ lookup, defaultValue })
      },

      showSocialSecurityInfoCard () {
        const lookup = 'INCLUDE_SOCIAL_SECURITY_NUMBER_CARD'
        const defaultValue = false
        return this.jsonifyTrueOrFalseOrDefault({ lookup, defaultValue })
      },

      // Steps for widget
      stepsAsFlatList () {
        const _steps = []
        let i = 0
        for (i in this.enumSteps) {
          _steps.push(this.enumSteps[i].title)
        }
        return _steps
      },

      // Utilities
      computedOverviewImages () {
        return this.overviewImages.map(this.mapOverviewImage)
      },

      displayedImageCountIndex () {
        // TODO: Extract this into Vuex, when 'step' gets into Vuex too.
        const min = this.imageStepRange[0]
        const max = this.imageStepRange[1]
        const isImageStep = (min <= this.step && this.step < max)

        let index = -1
        if (isImageStep) {
          // NOTE: The use of 'imageCountIndex on the image' does not work
          //   yet because active image returns a Promise and it has not
          //   been solved how to address this inside the computed property
          //   (or move it to Vuex state).
          const activeImage = this.getActiveImage()
          if (activeImage.imageCountIndex) {
            index = activeImage.imageCountIndex
          } else {
            index = this.step - this.imageFunnelStartIndex
            console.log('No imageCountIndex found. Tried to infer it instead')
          }
        }
        return index
      },

      nrImagesToTake () {
        const len = this.imagesToTakeByPointOfView.length
        let nr = this.defaultNrImagesToTake
        if (!this.imagesToTakeByPointOfView.includes(this.ASTERIX_WILDCARD) && len) {
          nr = len
        }
        return nr
      },

      headerTextOpacity () {
        const opacityDenominator = ((this.offsetTop) * 0.1) || 1
        let opacity = 1 / opacityDenominator
        if (opacity < 0) {
          opacity = 1 // On "scrolling upwards"
        } else if (opacity < 0.1) {
          opacity = 0
        }
        return opacity
      },

      isNativeShareSupported () {
        return !!navigator.share
      },

      imageCompression () {
        const _percentageGain = (this.imageSizeCompressed - this.imageSizeRaw) / this.imageSizeRaw
        const percentageGain = _percentageGain * 100
        return Math.round(Math.abs(percentageGain))
      },
    },

    watch: {
      iframeLoading (val) {
        if (!val) return
        setTimeout(() => (this.iframeLoading = false), 5000)
      },
    },

    created () {
      this.addEventListenerForFadeInComponents()
      this.$store.loadStoreModule({ module: Store })
      const appDiv = document.getElementById('app')
      if (appDiv) { appDiv.setAttribute('style', 'background: white;') }
    },

    async mounted () {
      /** NOTE: Widget is assumed loaded in an iFrame w. query params in URL.
       *  NOTE: iFrame seems to be loading twice. Before and after query
       *    params are accessible. if (!clinicWidgetId) { return } currently
       *    guards the first case.
      */
      const clinicWidgetId = this.$route.query.provider
      this.clinicWidgetId = clinicWidgetId
      try {
        const response = await validationApiServices.getClinicLocales({ clinicWidgetId })
        if (response.data.data === undefined) {
          airbrake.notify({ error: `getClinicLocales:${response}` })
        }
        const attribs = response.data.data.attributes
        this.widgetUuid = attribs.widget_uuid
        this.widgetType = attribs.widget_type
        this.landingPageType = attribs.landing_page_type
        this.landingPageType = attribs.landing_page_type
        const messages = attribs.clinic_widget_locales_as_json
        const fallbackLanguage = attribs.fallback_locales_language
        await loadLanguageAsync({ clinicWidgetId, messages, fallbackLanguage })
      } catch (e) {
        this.$router.push('/not-found')
        throw e
      }
      this.sessionId = this.$route.query.session || ''
      this.trafficSource = this.$route.query.source || ''

      const _embedded = 'generic_embedded' // TODO: Extract as config
      if (this.landingPageType === _embedded) {
        this.showCloseButton = false
      }

      this.setCustomThemeColor()

      this.fadeInCardComponents() // TODO: Consider how to implement this.

      // NOTE: The images are set to state here, but the actual update on which
      //   images to is applied `updateWhichImagesToSkip`.
      const _images = this.$t('DEFAULT_IMAGES_TO_TAKE_BY_POINT_OF_VIEW_LIST')
      if (_images) {
        this.setDefaultImagesToTakeByPointOfView(_images)
      }

      // HACKY FIX: Reset selectedUserIntents after loading dynamic locales.
      //   For some reaon the list is set to null and checkboxes are getting
      //   prepoulated in UI but not data-wise when user intents are not 1:1
      //   matching with default values for the language. #painful
      this.setUserResponse({ topic: 'userIntents', response: [] })

      // Current implementation always starts a new assessment...
      await this.initializeNewAssessment()

      // ... and then attempts to prepopulate its data.
      const existingAssessmentUuid = this.$route.query.resume
      if (existingAssessmentUuid) {
        await this.prepopulateFromPreviousAssessment(existingAssessmentUuid)
      }

      this.loadGoogleAnalytics(this.$t(this.analyticsLookupKey), 'google-analytics-adent-div')
      this.loadFacebookPixel(this.$t(this.pixelLookupKey), 'provider-facebook-pixel')
      this.loadHotjarRecorder(process.env.VUE_APP_ADENT_HOTJAR_APP_ID, 'adent-hotjar-script-id')
      this.loadAutopilot(process.env.VUE_APP_ADENT_AUTOPILOT_ID, 'autopilot-analytics-adent-div')

      window.addEventListener('load', () => {
        this.loadLazyComponents = true
      })
    },

    errorCaptured (err, vm, info) {
      this.errorMessage = 'Something went wrong. Refresh the page and try again.'
      this.errorTraceback = err
      this.errorSnackbar = true
      this.isLoading = false
    },

    methods: {
      ...mapActions('currentPage', [
        'populatePresignedUrls',
        'updateWhichImagesToSkip',
      ]),
      ...mapMutations({
        clearStore: 'currentPage/clear',
        setUserResponse: 'currentPage/setUserResponse',
        setIsInformedConsentGiven: 'currentPage/setIsInformedConsentGiven',
        setDefaultImagesToTakeByPointOfView: 'currentPage/setDefaultImagesToTakeByPointOfView',
      }),
      async next (valid) {
        /** Intended main flow (attempted simplest logic):
         *
         *  1) Get new assessment, and assign to clinic (done on 'mounted')
         *  2) Fetch presigned urls if ready, and not done before
         *  3) Submit email asap, to allow follow up on drop-out (not handled here)
         *  4) Upload images when they are loaded (not handled here)
         *  5) Submit other data at final submit (to simplify logic for now)
         */
        const fromStep = this.step
        const toStep = fromStep + 1

        // Pure rendering state
        if (fromStep >= 0) {
          this.headerHeight = this.defaultHeaderHeightDuringFlow
          this.showAvatarToolbar = true
          this.welcomeTextExplicitlyHidden = true
        }

        // Increment steps...
        const finalSubmitStep = (fromStep === this.enumSteps.length - 2)
        const finishedStep = (fromStep === this.enumSteps.length - 1)

        if (finalSubmitStep || finishedStep) {
          // See finalSubmit below.
        } else {
          this.step = toStep
          this.$emit('click:next')
        }

        const isImageStep = await this.isToStepAnImageStep(toStep)
        if (isImageStep) {
          const activeImage = await this.getActiveImage()
          if (activeImage.skipped) {
            this.next() // NOTE: Be aware this is recursive.
          }
        }

        // Edge-case: Prepopulate email with already given shared email
        if (this.shareEmail && !this.userResponses.email) {
          this.setUserResponse({ topic: 'email', response: this.shareEmail })
        }

        // Send confirmation email to user as soon as possible
        // const theStepAfterAddingEmail = 2 // TODO: Extract this as config
        // const safeToSendConfirmationEmail = Boolean(
        //   this.userResponses.email &&
        //     this.availableSteps.includes(theStepAfterAddingEmail) &&
        //     !this.isConfirmationEmailSent
        // )
        // if (safeToSendConfirmationEmail) {
        //   this.sendConfirmationEmail()
        //   this.isConfirmationEmailSent = true
        // }

        // Get presigned urls, when ready...
        const readyToFetchImageSlots = Boolean(this.assessmentUuid)
        const hasFetchedImageSlots = (this.uploadImages.length !== 0)

        if (readyToFetchImageSlots && !hasFetchedImageSlots) {
          await this.populatePresignedUrls(this.assessmentUuid)
          try {
            this.updateWhichImagesToSkip()
          } catch (e) {
            console.log(`ERROR:failed to update skip-images. ${e}`)
          }
          try {
            const estimatedUploadSpeed = await imageApiServices.checkConnectionByUploadSpeed()
            if (estimatedUploadSpeed.tag === 'slow') {
              this.compressImagesOnUpload = true
            }
          } catch (e) {
            airbrake.notify({
              error: e,
              context: { assessmentUuid: this.assessmentUuid },
            })
          }
        }

        // Submit form on last step...
        // NOTE on implementing the validation layers, e.g. that email is
        //   added or images are missing: Make sure that these follow each
        //   other in a meaningful way, when updating. It is easy to make
        //   a gliche in the experienced UX.
        const isFinalSubmit = finalSubmitStep
        if (isFinalSubmit && !this.userResponses.email) {
          this.requiredInformationIsMissingDialog = true
          return // return before submit
        }
        const imagesTakenStats = this.getImagesTakenStats()
        const imagesTaken = imagesTakenStats.imagesTaken
        this.nrImagesTaken = imagesTaken
        const imagesMissing = (imagesTakenStats.imagesNotTaken > 0)
        if (isFinalSubmit && imagesMissing && !this.imagesMissingDialogAlreadyPrompted) {
          this.imagesMissingDialog = true

          const minImagesToTake = this.minNrImagesToTake
          const requiredImagesMissing = (imagesTaken < minImagesToTake)
          if (!requiredImagesMissing) {
            this.imagesMissingDialogAlreadyPrompted = true
          }
          return // return before submit
        }
        if (isFinalSubmit && !this.isSubmitted) {
          this.isSubmitted = true // NOTE: Set early to avoid 'double-tap'
          try {
            await this._finalSubmit()
            this.step = toStep
            this.$emit('click:next')
          } catch (e) {
            this.isSubmitted = false
            this.errorSnackbar = true
            // TODO: Add a action to take for users here to "save them".
            this.errorMessage = 'Sorry, something went wrong while submitting.'
            throw e
          } finally {
            this.isLoading = false
          }
        }
        // Analytics (additional to what is recorded in other calls)
        const isUserIntentGiven = !!this.userResponses.userIntents.length
        const isBaseInfoGiven = !!this.userResponses.email
        if (isUserIntentGiven && !this.isUserIntentRecordedOnAnalytics) {
          await this.trackUserIntentSubmitted()
          this.isUserIntentRecordedOnAnalytics = true
        }
        if (isBaseInfoGiven && !this.isBaseInfoGivenRecordedOnAnalytics) {
          await this.trackBaseInfoSubmitted()
          this.isBaseInfoGivenRecordedOnAnalytics = true
        }

        try {
          await this.saveLatestStateToPersistentStore()
        } catch (e) {
          console.log(`State failed to save to persistent store: ${e}`)
        }
      },

      async isToStepAnImageStep (toStep) {
        const _imageMinRange = this.imageFunnelStartIndex
        const _imageMaxRange = this.imageFunnelStartIndex + this.maxNrOfImagesToUpload
        const _isImageStep = (_imageMinRange <= toStep && toStep < _imageMaxRange)
        return _isImageStep
      },

      async stepBack () {
        const fromStep = this.step
        const toStep = fromStep - 1

        this.step = toStep
        this.$emit('click:stepBack')

        // TODO: Uncomment this after debugging 2020/09/26! Maybe
        //   resulting in a crash. Maybe not. Alternatively make
        //   it play nice without 'await', if possible. That is the
        //   only part that worries me (although not confirmed an
        //   issue at all).
        const isImageStep = await this.isToStepAnImageStep(toStep)
        if (isImageStep) {
          const activeImage = await this.getActiveImage()
          if (activeImage.skipped) {
            this.stepBack() // NOTE: Be aware this is recursive.
          }
        }

        if (toStep === 0) {
          this.showAvatarToolbar = false
          this.headerHeight = this.defaultHeaderHeightInitialScreen
          this.welcomeTextExplicitlyHidden = false
        }
      },

      fadeInCardComponents (timeout0 = 0, timeout1 = 0, timeout2 = 1000, timeout3 = 1200, timeout4 = 1200) {
        /** Pure UX, to achieve feeling of 'depth' */
        const that = this
        setTimeout(function () {
          that.showComponent0 = true
        }, timeout0)
        setTimeout(function () {
          that.showComponent1 = true
        }, timeout1)
        setTimeout(function () {
          that.showComponent2 = true
        }, timeout2)
        setTimeout(function () {
          that.showComponent3 = true
        }, timeout3)
        setTimeout(function () {
          that.showComponent4 = true
        }, timeout4)
      },

      fadeOutCardComponents () {
        /** Pure UX, to achieve feeling of 'depth' */
        this.showComponent0 = false
        this.showComponent1 = false
        this.showComponent2 = false
        this.showComponent3 = false
        this.showComponent4 = false
      },

      async setEmailAndSubmit () {
        const topic = 'email'
        const response = this.internalUserResponses.email
        await this.setUserResponse({ topic: topic, response: response })
        this.next()
      },

      addEventListenerForFadeInComponents () {
        const that = this
        window.addEventListener('message', receiveMessage, false)
        function receiveMessage (event) {
          // SECURITY NOTE: All domains are trusted for the simple, harmless
          //   operations of 'open' and 'close'. Do NOT add more complex logic
          //   that can be exploited. If doing so, validate the domains before
          //   any processing occurs. Do NOT trust the sender here.
          const isDomainTrusted = true
          if (!isDomainTrusted) { return }
          if (event.data.closeWidget === true) {
            that.fadeOutCardComponents()
          }
          if (event.data.openWidget === true) {
            that.fadeInCardComponents()
            if (!that.isWidgetInitializedRecordedOnAnalytics) {
              that.trackWidgetInitializedAndDeviceStats()
              that.isWidgetInitializedRecordedOnAnalytics = true
            }
          }
        }
      },

      async getActiveInternalImageCountIndex () {
        return await this.step - this.imageFunnelStartIndex
      },

      async getActiveImage () {
        const internalImageCountIndex = await this.getActiveInternalImageCountIndex()
        const activeImage = await this.uploadImages[internalImageCountIndex]
        return activeImage
      },

      async _finalSubmit () {
        let that = this

        this.isLoading = true
        const intents = await that.userResponses.userIntents
        const intentsList = await that.userIntentOptionsList
        const mappedUserIntents = await this.bulkMapSelectedResponses(intents, intentsList)
        const topicOfInterestUuid = that.userResponses.userIntents[0] // TODO: Consider

        const serializedUserResponses = submitApiServices.serializeFunnelUserResponses({
          email: that.userResponses.email,
          emailQuestion: that.$t('QUESTION_RELATED_TO_EMAIL'),
          name: that.userResponses.name,
          nameQuestion: that.$t('QUESTION_RELATED_TO_NAME'),
          phone: that.userResponses.phone,
          phoneQuestion: that.$t('QUESTION_RELATED_TO_PHONE'),
          year: that.userResponses.year,
          yearQuestion: that.$t('QUESTION_RELATED_TO_AGE_IN_YEARS'),
          socialSecurityNumber: that.userResponses.socialSecurityNumber,
          socialSecurityNumberQuestion: that.$t('QUESTION_RELATED_TO_SOCIAL_SECURITY_NUMBER'),
          selectedUserIntents: mappedUserIntents,
          userIntentsQuestion: that.$t('QUESTION_RELATED_TO_USER_INTENT'),
          primaryGoal: that.userResponses.primaryGoal,
          primaryGoalQuestion: that.$t('QUESTION_RELATED_TO_PRIMARY_GOAL'),
          feedback: that.userResponses.feedback,
          feedbackQuestion: that.$t('REQUIRED_IMAGES_MISSING_FEEDBACK_CARD_TEXT'),
        })

        // TODO: Consider this implementation, as it is "hacky" for now. But
        //   remember the hacky solution is intentional until things stabilize.
        let clinicUuid = null
        let groupUuids = null
        if (this.$t('IS_CLINIC_AND_GROUPS_ASSIGNED_EXPLICITLY') === 'true') {
          clinicUuid = this.explicitClinic.uuid
          groupUuids = this.explicitClinic.groups
        }

        await submitApiServices.submitFinal({
          assessmentUuid: that.assessmentUuid,
          topicOfInterestUuid: topicOfInterestUuid,
          isInformedConsentGiven: that.isInformedConsentGiven,
          serializedUserResponses: serializedUserResponses,
          widgetUuid: this.widgetUuid,
          clinicUuid: clinicUuid,
          groupUuids: groupUuids,
          nrImagesTaken: this.nrImagesTaken,
          nrImagesPromptedToTake: this.nrImagesToTake,
        })
        this.trackFinalSubmit()
      },

      async validateClinicByWidgetIdAndSetWidgetUuid (clinicWidgetId) {
        try {
          const response = await validationApiServices.getClinicLocales({ clinicWidgetId: clinicWidgetId })
          const widgetUuid = response.data.data.attributes.widget_uuid
          this.widgetUuid = widgetUuid
        } catch (e) {
          // handle error towards user
          throw e
        }
      },

      saveEmail (email) {
        /** Store to prepopulate later, if relevant */
        this.shareEmail = email
      },

      // async sendConfirmationEmail () {
      //   const assessmentUuid = this.assessmentUuid
      //   const widgetUuid = this.widgetUuid
      //   const email = this.userResponses.email
      //   const name = this.userResponses.name
      //   const phoneNumber = this.userResponses.phone
      //   await submitApiServices.manuallyTriggerEmailAutomationJourney({
      //     assessmentUuid: assessmentUuid,
      //     email: email,
      //     name: name,
      //     phoneNumber: phoneNumber,
      //     widgetUuid: widgetUuid,
      //   })
      // },

      async onImageInputChange (event) {
        /** Uploads an image.
         *
         * Tries:
         * ... To upload the image 'as is' on change (main case).
         * ... can be retried.
         * ... if 'timeout' (assumes low bandwidth) prompt user.
         * ... if also 'timeout' after resizing image, prompt user again.
         */
        let newFile = null
        if (event && event.target && event.target.files) {
          newFile = event.target.files[0]
        }
        const activeImage = await this.getActiveImage()

        const noFileFound = (!newFile && !activeImage.file)
        const noChange = (!newFile && activeImage.file && !this.isRetryUpload)
        const isRetry = (!newFile && activeImage.file && this.isRetryUpload)
        const compress = this.compressImagesOnUpload

        if (noFileFound) {
          throw new Error('No file when attempting to upload')
        } else if (noChange) {
          console.info('Image capture change event triggered, but file not changed.')
          return
        }

        if (newFile) {
          activeImage.displayableImageUrl = URL.createObjectURL(newFile) // TODO: Is this memory intensive?
        }
        const url = activeImage.url
        let that = this
        that.isLoading = true

        if (isRetry && !compress) {
          // Retry -> upload already loaded file
          try {
            await imageApiServices.uploadImage({ url: url, file: activeImage.file })
            that.isLoading = false
            activeImage.uploaded = true
          } catch (e) {
            await that._handleErrorOnRetryUpload(e)
          } finally {
            that.isRetryUpload = false // Resets
          }
        } else if (!isRetry && !compress) {
          // Raw upload -> upload raw file input
          activeImage.file = await newFile
          this.imageSizeRaw = newFile.size
          try {
            await imageApiServices.uploadImage({ url: url, file: newFile })
            that.isLoading = false
            activeImage.uploaded = true
          } catch (e) {
            await that._handleErrorOnRawUpload(e)
          }
        } else if (compress) {
          // Compress -> resize and upload (and override raw file in memory)
          let file
          if (newFile) {
            file = newFile
          } else {
            file = activeImage.file
          }
          ImageTools.resize(file, {
            width: 640,
            height: 480,
          }, async function (blob, didItResize) {
            try {
              await imageApiServices.uploadImage({ url: url, file: blob })
              that.isLoading = false
              activeImage.uploaded = true
            } catch (e) {
              await that._handleErrorOnCompressedUpload(e)
            } finally {
              activeImage.file = await blob
              that.imageSizeCompressed = blob.size
            }
          })
        }
      },

      getImagesTakenStats () {
        const images = this.uploadImages
        let imagesTaken = 0
        let imagesNotTaken = 0
        let imagesToTake = 0
        let imagesToBeSkipped = 0
        let i = 0
        for (i in images) {
          const image = images[i]
          if (image.skipped) {
            imagesToBeSkipped += 1
          } else if (image.file) {
            imagesTaken += 1
            imagesToTake += 1
          } else {
            imagesNotTaken += 1
            imagesToTake += 1
          }
        }
        return { imagesTaken: imagesTaken, imagesNotTaken: imagesNotTaken, imagesToBeSkipped: imagesToBeSkipped, imagesToTake: imagesToTake }
      },

      async _handleErrorOnRetryUpload (error) {
        this.isLoading = false
        const that = this
        if (this.isTimedOut(error)) {
          if (!this.compressImagesOnUpload) {
            that.showCompressImageDialog = true
          }
          // Add airbreak
        } else {
          throw new Error(`File could not upload. That's unusual. Please try again. Failed with: ${error}`)
        }
      },

      async _handleErrorOnRawUpload (error) {
        this.isLoading = false
        const that = this
        if (this.isTimedOut(error)) {
          that.isLowBandwidthDetected = true
          if (!this.compressImagesOnUpload) {
            that.showCompressImageDialog = true
          }
          // Add airbreak
        } else {
          throw new Error(`File could not upload. That's unusual. Please try again. Failed with: ${error}`)
        }
      },

      async _handleErrorOnCompressedUpload (error) {
        this.isLoading = false
        const that = this
        if (this.isTimedOut(error)) {
          that.showFailedCompressedUploadDialog = true
        } else {
          throw new Error(`File could not upload. That's unusual. Please try again. Failed with: ${error}`)
        }
        // Add airbreak
      },

      isTimedOut (error) {
        return error.message.includes('timeout')
      },

      async initializeNewAssessment () {
        const widgetUuid = this.widgetUuid
        const assessmentTypeUuid = this.assessmentTypeUuid
        const originUrlAsBase64 = this.trafficSource
        const assessmentUuid = await submitApiServices.getNewAssessment({
          widgetUuid,
          assessmentTypeUuid,
          originUrlAsBase64,
        })
        this.assessmentUuid = assessmentUuid
      },

      async prepopulateFromPreviousAssessment (assessmentUuid) {
        try {
          const response = await submitApiServices.retrievePreviouslyStartedAssessment({ assessmentUuid })
          const prepopulatedData = await parserUtils.parsePrepopulatedLastestStateAssessmentResource(response.data.data)
          if (!this.userResponses.email) {
            this.setUserResponse({ topic: 'email', response: prepopulatedData.email })
            this.setUserResponse({ topic: 'phone', response: prepopulatedData.phone })
            this.setUserResponse({ topic: 'name', response: prepopulatedData.name })
            this.setUserResponse({ topic: 'year', response: prepopulatedData.year })
            this.setUserResponse({ topic: 'userIntents', response: prepopulatedData.userIntents })
            this.setUserResponse({ topic: 'primaryGoal', response: prepopulatedData.primaryGoal })
            // Etc. Add as needed and as available.
          } else {
            console.log('It seems data is already loaded. Not loading in fear of overriding newer thing.')
          }
          this._showSuccessfullyResumedAssessmentPrompt()
        } catch (e) {
          // TODO: Consider to show error to user, but current assumption is
          //   that it is not perceived as a bug if nothing happens.
          airbrake.notify({
            error: e,
            context: { assessmentUuid: this.assessmentUuid },
          })
        }
      },

      _showSuccessfullyResumedAssessmentPrompt (timeout = 1000) {
        /** Pure UX utility. */
        const that = this
        setTimeout(function () {
          that.primarySnackbar = true
          that.primarySnackbarText = that.$t('SUCCESS_RESUMING_PREVIOUS_ASSESSMENT')
        }, timeout)
      },

      saveLatestStateToPersistentStore () {
        submitApiServices.saveLatestStateToPersistentStore({
          assessmentUuid: this.assessmentUuid,
          userResponses: this.userResponses,
        })
      },

      getLocalesByLookupOrNone (lookup) {
        let locales = this.$t(lookup)
        if (locales === lookup) {
          locales = null
        }
        return locales
      },

      jsonifyTrueOrFalseOrDefault ({ lookup, defaultValue }) {
        const _locales = this.getLocalesByLookupOrNone(lookup)
        let locales = defaultValue
        if (_locales === 'false') {
          locales = false
        } else if (_locales === 'true') {
          locales = true
        } else if (_locales) {
          console.log(`Unknown locales, expected true/false: ${_locales}`)
        }
        return locales
      },

      mapSection (item) {
        return {
          ...item,
          title: this.$t(item.title),
        }
      },
      mapUserResponseOption (item) {
        return {
          ...item,
          label: this.$t(item.label),
        }
      },

      mapOverviewImage (item) {
        return {
          ...item,
          text: this.$t(item.text),
        }
      },

      isImageLoadedToMemory (index) {
        return (this.uploadImages[index] && this.uploadImages[index].displayableImageUrl)
      },

      isMobile () {
        return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
      },

      checkChangeAnticipated (step) {
        const expectedAvailableSteps = this.availableSteps.length - 1
        if (step >= expectedAvailableSteps) {
          this.showDidYouSlideDialog()
        }
      },

      updateResponse (payload) {
        this.setUserResponse(payload)
      },

      updateFeedback () {
        const topic = 'feedback'
        const response = this.internalUserResponses.feedback
        this.setUserResponse({ topic: topic, response: response })
      },

      showDidYouSlideDialog () {
        this.didYouSlideDialog = true
      },

      async bulkMapSelectedResponses (selectedResponses, optionsList) {
        const mappedResponses = []
        let i = 0
        for (i in selectedResponses) {
          const _item = await this.mapSelectedResponse(selectedResponses[i], optionsList)
          mappedResponses.push(_item)
        }
        return mappedResponses
      },

      async mapSelectedResponse (responseId, optionsList) {
        /** Maps user intent uuid to localized text, through localization variable
        */
        let text = ''
        let _option = {}
        let i = 0
        for (i in optionsList) {
          _option = await optionsList[i]
          const _idFromList = _option.value
          if (_idFromList === responseId) {
            const _lookup = _option.label
            text = this.$t(`${_lookup}`)
            break
          }
        }
        const responseMappedToLanguage = {
          ..._option,
          text: text,
        }
        return responseMappedToLanguage
      },

      calculateIframeHeight () {
        const toolbarHeight = this._getToolbarHeight()
        const windowHeight = window.innerHeight
        this.iframeHeight = windowHeight - toolbarHeight
      },
      _getToolbarHeight () {
        /** Currently hardcoded, have not succeeded to fetch dynamically via $refs.
         *
         * Dimensions from intrspection are:
         *   - 56px on small screens
         *   - 64px on bigger screens
         *
         * Using 64 leaves a slim white border in the bottom of the shown iframe
         * but it avoids any potential "overflow" (causing scroll) on the dialog.
         *
         * Intended style, but not working:
         *   `this.$refs.frameToolbar.clientHeight`
        */
        return 64
      },
      loadLinkInIframe (url) {
        this.activeIframeUrl = url
      },
      onScroll (e) {
        this.offsetTop = window.scrollY
      },
      trackWidgetInitializedAndDeviceStats () {
        // NOTE: We currently stream analytics stats into 4 different sources:
        //   1) Google Analytics
        //   2) Facebook
        //   3) Adent's own database (used in Clinic Portal)
        //   4) Airbrake for errors
        // NOTE: There is an edge-case where this is triggered before load of
        //   the assessment uuid from the URL. In that case this analytics
        //   endpoint can return a 500 Error (invalid UUID). Consider handling.
        const eventName = 'WidgetInitialized'
        const providerId = this.widgetUuid
        let errors = []
        try {
          analyticsEndpoints.analyticsToInitializeWidgetAndDeviceStats({
            assessmentUuid: this.assessmentUuid,
            sessionId: this.sessionId,
            trafficSource: this.trafficSource,
          })
        } catch (e) {
          errors.push(e)
        }
        try {
          window.fbq('trackCustom', eventName, { assessmentUuid: this.assessmentUuid })
        } catch (e) {
          // do nothing, fb-tracking is a clinic-feature and fails if not pixel is provided
        }
        try {
          window.gtag('event', eventName, { 'event_category': 'funnel_widget', provider: providerId })
        } catch (e) {
          errors.push(e)
        }
        if (errors.length > 0) {
          const errType = errors[0].message
          airbrake.notify({ error: `trackingError-${errType}`, context: errors })
        }
      },
      trackUserIntentSubmitted () {
        const eventName = 'UserIntentSubmitted'
        const assessmentUuid = this.assessmentUuid
        const providerId = this.widgetUuid
        let errors = []
        try {
          window.fbq('trackCustom', eventName, { assessmentUuid: assessmentUuid, userIntent: this.userResponses.userIntents[0] })
        } catch (e) {
          // ditto, as above
        }
        try {
          window.gtag('event', eventName, { 'event_category': 'funnel_widget', provider: providerId })
        } catch (e) {
          errors.push(e)
        }
        try {
          const eventValue = { provider: providerId }
          window.AF('pba', 'event', { eventType: 'EVENT', eventCategory: 'conversion', eventName: eventName, eventLabel: 'funnel_widget', eventValue: eventValue })
        } catch (e) {
          errors.push(e)
        }
        if (errors.length > 0) {
          airbrake.notify({ error: 'trackingError', context: errors })
        }
      },
      trackContinueFromFirstScreen () {
        const eventName = 'ContinueFromFirstScreen'
        const assessmentUuid = this.assessmentUuid
        const providerId = this.widgetUuid
        let errors = []
        try {
          window.fbq('trackCustom', eventName, { assessmentUuid: assessmentUuid })
        } catch (e) {
          // ditto, as above
        }
        try {
          window.gtag('event', eventName, { 'event_category': 'funnel_widget' })
        } catch (e) {
          errors.push(e)
        }
        try {
          const eventValue = { provider: providerId }
          window.AF('pba', 'event', { eventType: 'EVENT', eventCategory: 'conversion', eventName: eventName, eventLabel: 'funnel_widget', eventValue: eventValue })
        } catch (e) {
          errors.push(e)
        }
        if (errors.length > 0) {
          airbrake.notify({ error: 'trackingError', context: errors })
        }
      },
      trackBaseInfoSubmitted () {
        const eventName = 'UserBaseInfoSubmitted'
        const assessmentUuid = this.assessmentUuid
        const providerId = this.widgetUuid
        let errors = []
        try {
          analyticsEndpoints.analyticsForBaseInfo(assessmentUuid)
        } catch (e) {
          errors.push(e)
        }
        try {
          window.fbq('trackCustom', eventName, { assessmentUuid: assessmentUuid })
        } catch (e) {
          // ditto, as above
        }
        try {
          window.gtag('event', eventName, { 'event_category': 'funnel_widget', provider: providerId })
        } catch (e) {
          errors.push(e)
        }
        try {
          const eventValue = { provider: providerId }
          window.AF('pba', 'event', { eventType: 'EVENT', eventCategory: 'conversion', eventName: eventName, eventLabel: 'funnel_widget', eventValue: eventValue })
        } catch (e) {
          errors.push(e)
        }
        if (errors.length > 0) {
          airbrake.notify({ error: 'trackingError', context: errors })
        }
      },
      trackFinalSubmit () {
        const eventName = 'WidgetFinalSubmit'
        const assessmentUuid = this.assessmentUuid
        const providerId = this.widgetUuid
        let errors = []
        try {
          analyticsEndpoints.analyticsForBaseInfo(assessmentUuid)
        } catch (e) {
          errors.push(e)
        }
        try {
          window.fbq('trackCustom', eventName, { assessmentUuid: assessmentUuid })
        } catch (e) {
          // ditto, as above
        }
        try {
          window.gtag('event', eventName, { 'event_category': 'funnel_widget', provider: providerId })
        } catch (e) {
          errors.push(e)
        }
        try {
          const eventValue = { provider: providerId }
          window.AF('pba', 'event', { eventType: 'EVENT', eventCategory: 'conversion', eventName: eventName, eventLabel: 'funnel_widget', eventValue: eventValue })
        } catch (e) {
          errors.push(e)
        }
        if (errors.length > 0) {
          airbrake.notify({ error: 'trackingError', context: errors })
        }
      },
      loadFacebookPixel (pixelId, divId) {
        if (!pixelId) { return }
        if (pixelId === this.pixelLookupKey) { return }
        const pixelTemplate = pixelLoader.load(pixelId)
        let pixelScript = document.createElement('script')
        pixelScript.setAttribute('id', divId)
        pixelScript.innerHTML = pixelTemplate
        document.head.appendChild(pixelScript)
      },
      loadHotjarRecorder (appId, divId) {
        if (!appId) { return }
        const hotjarTemplate = hotjarLoader.load(appId)
        let loaderScript = document.createElement('script')
        loaderScript.setAttribute('id', divId)
        loaderScript.innerHTML = hotjarTemplate
        document.head.appendChild(loaderScript)
      },
      loadGoogleAnalytics (appId, divId) {
        // NOTE: Take note of the double-script load.
        if (!appId) { return }
        const src = analyticsLoader.src(appId)
        let srcScript = document.createElement('script')
        srcScript.setAttribute('id', `${divId}-src`)
        srcScript.setAttribute('src', src)
        srcScript.setAttribute('async', true)
        const template = analyticsLoader.init(appId)
        let loaderScript = document.createElement('script')
        loaderScript.setAttribute('id', `${divId}-init`)
        loaderScript.innerHTML = template
        document.head.appendChild(srcScript)
        document.head.appendChild(loaderScript)
      },
      loadAutopilot (appId, divId) {
        if (!appId) { return }
        const scriptTemplate = autopilotLoader.load(appId)
        let scriptTag = document.createElement('script')
        scriptTag.setAttribute('id', divId)
        scriptTag.innerHTML = scriptTemplate
        document.head.appendChild(scriptTag)
      },

      isObjectEmpty (obj) {
        return Object.keys(obj).length === 0 && obj.constructor === Object
      },

      animateNrImagesToTakeHasChanged (timeout = 1000) {
        const that = this
        that.showSignOfNrImagesToTakeHasChanged = true
        setTimeout(() => (that.showSignOfNrImagesToTakeHasChanged = false), timeout)
      },

      async copyWidgetLinkToClipboard () {
        const link = await this.buildFunnelWidgetLink()
        var _tmp = document.createElement('textarea')
        document.body.appendChild(_tmp)
        _tmp.value = link
        _tmp.select()
        document.execCommand('copy')
        document.body.removeChild(_tmp)
        this._showSuccessSnackbarMessage(this.$t('LINK_COPIED_TO_CLIPBOARD'))
      },

      buildFunnelWidgetLink () {
        const baseUrl = process.env.VUE_APP_GENERIC_LANDING_PAGE_BASE_URL
        return widgetUrlServices.getFunnelWidgetUrl({ clinicWidgetId: this.clinicWidgetId, baseUrl: baseUrl })
      },

      async shareLinkNativeShare () {
        if (navigator.share) {
          try {
            await navigator.share({
              title: this.$t('SHARE_WITH_OTHER_NATIVE_SHARE_LINK_TITLE'),
              text: this.$t('SHARE_WITH_OTHER_NATIVE_SHARE_LINK_TEXT'),
              url: await this.buildFunnelWidgetLink(),
            })
            const msg = this.$t('SHARE_WITH_OTHER_NATIVE_SHARE_SUCCESSFULLY_SENT_MESSAGE')
            this.isLinkShared = true
            this._showSuccessSnackbarMessage(msg)
          } catch (e) {
            // TODO: Catch and ignore AbortError only. Display other errors as failure.
            // const msg = this.$t('SHARE_WITH_OTHER_NATIVE_SHARE_FAILED_TO_SEND_MESSAGE')
            // this._showErrorShareMessage(msg, e)
          }
        } else {
          // NOTE: Case assumed never hit, since button can be hidden when unsupported
          const msg = this.$t('SHARE_WITH_OTHER_NATIVE_SHARE_NOT_SUPPORTED')
          this._showErrorShareMessage(msg, undefined)
        }
      },

      _showSuccessSnackbarMessage (msg, timeout = 500) {
        /** Pure UX utility. */
        const that = this
        setTimeout(function () {
          that.primarySnackbar = true
          that.primarySnackbarText = msg
        }, timeout)
      },

      _showErrorShareMessage (msg, error, timeout = 500) {
        /** Pure UX utility. */
        const that = this
        setTimeout(function () {
          that.errorSnackbar = true
          that.errorMessage = msg
          that.errorTraceback = error
        }, timeout)
      },

      setCustomThemeColor () {
        /** See note for Funnel Widget. */
        const _colorFromLocales = 'PROVIDER_THEME_COLOR'
        let customThemeColor
        if (colorUtils.isValidHex(this.$t(_colorFromLocales))) {
          customThemeColor = this.$t(_colorFromLocales)
        }
        const isColorCustomized = (customThemeColor && !(customThemeColor === _colorFromLocales))
        if (isColorCustomized) {
          if (!customThemeColor.startsWith('#')) {
            customThemeColor = `#${customThemeColor}`
          }
          if (colorUtils.isValidHex(customThemeColor)) {
            this.$vuetify.theme.themes.light.primary = customThemeColor
          } else {
            // Add airbrake
          }
        }
      },

      updateIsInformedConsentGiven (event) {
        this.setIsInformedConsentGiven(event)
      },
    },
  }
</script>

<style lang="sass">
  .widget-display-2
    font-size: 18px !important

  .widget-card-title
    font-size: 16px !important
</style>
