<template>
  <div>
    <v-card
      class="mb-6"
    >
      <v-progress-linear
        absolute
        top
        height="2"
        color="primary"
      />
      <v-card-title class="widget-card-title">
        {{ cardTitle }}
      </v-card-title>
      <v-card-text>
        <div class="mb-2">
          {{ cardSubtitle }}
        </div>
        <v-text-field
          v-model="emailToShareWith"
          :label="isNotSent ? $t('LOOKS_LIKE_YOU_ARE_ON_DESKTOP_BY_EMAIL_CARD_FIELD_LABEL') : $t('SHARE_LINK_BY_EMAIL_SUBMIT_SUCCESS_TEXT')"
          outlined
          filled
          dense
          hide-details="auto"
          autocomplete="email"
          type="email"
        >
          <template slot="append-outer">
            <v-icon
              :disabled="!(emailToShareWith && isNotSent)"
              :color="(emailToShareWith && isNotSent) ? 'primary' : 'grey'"
              @click="sendEmail()"
            >
              {{ isNotSent ? 'mdi-send' : 'mdi-check' }}
            </v-icon>
          </template>
        </v-text-field>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import submitApiServices from '@/views/widgets/services/submit_endpoints'

  export default {
    name: 'ShareLinkByEmailCard',
    props: {
      assessmentUuid: {
        type: String,
        required: true,
      },
      cardTitle: {
        type: String,
        required: true,
      },
      cardSubtitle: {
        type: String,
        required: true,
      },
      widgetUuid: {
        type: String,
        required: true,
      },
    },

    data: () => ({
      emailToShareWith: '',
      isNotSent: true,
    }),

    methods: {
      async sendEmail () {
        const email = this.emailToShareWith
        if (!email) { return }
        await submitApiServices.shareLinkWithEmail({ widgetUuid: this.widgetUuid, assessmentUuid: this.assessmentUuid, email: email })
        this.changeIcon()
        this.clearEmail()
        this.$emit('save-email', email)
      },

      changeIcon () {
        this.isNotSent = !this.isNotSent
      },

      clearEmail () {
        this.emailToShareWith = ''
      },
    },
  }
</script>

<style lang="sass">
  .widget-display-2
    font-size: 18px !important
</style>
