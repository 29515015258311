<template>
  <div
    id="widget-container"
    v-scroll="onScroll"
  >
    <div
      id="widget-header-background"
      :style="`background: linear-gradient(135deg, ${primaryColor} 0%, ${primaryColorDarken} 100%); z-index: 1; height: ${headerHeight}px; width: 100%; position: fixed; top: 0; left: 0;`"
    />
    <div
      id="widget-body-actions"
    >
      <v-card
        elevation="0"
        transparent
        color="rgb(255, 255, 255, 0.0)"
        style="z-index: 2;"
        :min-height="getHeightByConstraint()"
      >
        <!-- NOTE: This button has no functionality here. It is placed
              where the close button (transparent) on the parent page.
              This is to protect the button's visuals/CSS from being
              distorted by the parent page. Implementation may be reconsidered.
         -->
        <v-btn
          v-if="showCloseButton"
          fab
          small
          text
          dark
          :style="{
            position: 'fixed',
            top: '8px',
            right: '8px',
            'z-index': '3',
          }"
          color="white"
        >
          <v-icon small>
            mdi-close
          </v-icon>
        </v-btn>
        <v-card-text
          class="mb-0"
          style="z-index: 2;"
        >
          <v-list-item
            v-show="showAvatarToolbar"
            class="grow"
            :style="`opacity: ${avatarToolbarOpacity}; z-index: 2;`"
          >
            <v-list-item-avatar
              color="grey darken-3"
              :size="avatarSize"
            >
              <v-img
                class="elevation-6"
                :src="$t('PROVIDER_DENTIST_PORTRAIT_URL')"
                :lazy-src="$t('PLACEHOLDER_PROVIDER_DENTIST_PORTRAIT_URL')"
              />
            </v-list-item-avatar>

            <!-- HACK: When adaptive number of images, then imageCountIndex is
                currently misleading. The:
                   `v-if="(imageCountIndex + 1) <= nrImagesToTake"`
                aka: if the displayed image number is greater than the number
                of images to take is a simple, hacky solution to avoid most
                UX issues concerned with this matter. TODO: Find a solution
                that fixes the technical problem, not only the UX.
            -->
            <div class="white--text caption">
              <div>
                {{ $t('PROVIDER_DENTIST_NAME_WITH_TITLE') }}
              </div>
              <div
                v-show="isStepsVisible && (imageCountIndex + 1) <= nrImagesToTake"
              >
                {{ $t('IMAGE_X_OUT_OF_Y_PREFIX_TEXT') }}
                {{ imageCountIndex + 1 }}
                {{ $t('IMAGE_X_OUT_OF_Y_MIDDLE_TEXT') }}
                {{ nrImagesToTake }}
                {{ $t('IMAGE_X_OUT_OF_Y_SUFFIX_TEXT') }}
              </div>
            </div>

            <v-row
              align="center"
              justify="end"
            >
              <v-progress-circular
                v-show="isLoading"
                size="24"
                indeterminate
                color="white"
              />
              <div class="mr-2" />
              <v-icon
                dark
                class="mr-1"
                @click="showSupportPrompt = !showSupportPrompt"
              >
                mdi-help-circle-outline
              </v-icon>
            </v-row>
          </v-list-item>

          <v-tabs-items
            v-model="internalValue"
            style="z-index: 2; background-color: rgba(0,0,255,0.0);"
          >
            <slot />
          </v-tabs-items>
        </v-card-text>

        <v-card-actions
          class="mb-16 mr-2"
        >
          <!-- Initial 'Start' button -->
          <v-slide-x-transition>
            <v-btn
              v-show="isFirstStep && isCardActionsVisible"
              large
              :style="{
                position: 'fixed',
                bottom: '55px',
                'z-index': '3',
                width: '95%',
              }"
              :color="initialStartButtonColor"
              min-width="100"
              @click="next(), $emit('continue-from-first-screen')"
            >
              {{ nextButtonText }}
            </v-btn>
          </v-slide-x-transition>

          <!-- All other 'Next'/'Previous' buttons -->
          <v-btn
            v-show="!isFirstStep && !isSecondStep && !isFinishedStep"
            text
            color="grey"
            min-width="100"
            @click="$emit('click:prev')"
          >
            {{ $t('WIDGET_PREVIOUS_BUTTON_TEXT') }}
          </v-btn>
          <v-spacer />
          <v-btn
            v-show="!isFirstStep && !isFinishedStep"
            :disabled="(internalValue !== 0 && !availableSteps.includes(internalValue + 1)) || isSubmitted"
            color="primary"
            min-width="100"
            @click="next()"
          >
            {{ nextButtonText }}
          </v-btn>
        </v-card-actions>

        <v-scroll-y-reverse-transition>
          <v-toolbar
            v-show="isBottomToolbarVisible"
            bottom
            dense
            :style="{
              position: 'fixed',
              bottom: '0px',
              'z-index': '4',
              width: '100%',
            }"
            elevation="5"
          >
            <v-row
              justify="center"
              align="center"
              no-gutters
            >
              <v-img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/66/Conformit%C3%A9_Europ%C3%A9enne_%28logo%29.svg/1200px-Conformit%C3%A9_Europ%C3%A9enne_%28logo%29.svg.png"
                max-height="17px"
                max-width="17px"
                contain
                aspect-ratio="1"
              />
              <div class="grey--text font-weight-light text--darken-1 ml-3">
                {{ $t('CE_FOOTER_TEXT') }}
              </div>
            </v-row>
          </v-toolbar>
        </v-scroll-y-reverse-transition>
      </v-card>
    </div>
    <v-dialog
      v-model="showSupportPrompt"
      width="80%"
      max-width="400"
    >
      <v-card>
        <v-card-title class="mb-1">
          {{ $t('HEADER_FOR_INFORMATION_BUTTON_TOP_TOOLBAR') }}
        </v-card-title>
        <v-card-subtitle>
          {{ $t('SUBHEADER_TEXT_FOR_INFORMATION_BUTTON_TOP_TOOLBAR') }}
        </v-card-subtitle>

        <v-card-text>
          <p>
            {{ $t('TECHNICAL_SUPPORT_TEXT_FOR_INFORMATION_BUTTON_TOP_TOOLBAR') }}
          </p>
          {{ $t('BODY_TEXT_FOR_INFORMATION_BUTTON_TOP_TOOLBAR') }}
          <p />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn
            text
            @click="showSupportPrompt = false"
          >
            {{ $t('DISMISS_BUTTON_TEXT_FOR_INFORMATION_BUTTON_TOP_TOOLBAR') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  // Mixins
  import Proxyable from 'vuetify/lib/mixins/proxyable'

  import colorUtils from '@/views/widgets/services/color_utils'

  export default {
    name: 'FunnelWidgetContainer',

    mixins: [Proxyable], // NOTE: This exposes the 'internalValue' property.

    props: {
      availableSteps: {
        type: Array,
        default: () => ([]),
      },
      steps: {
        type: Array,
        default: () => ([]),
      },
      activeStep: {
        type: Number,
        default: () => (0),
      },
      isLoading: {
        type: Boolean,
        default: () => (false),
      },
      headerHeight: {
        type: Number,
        default: () => (255),
      },
      showAvatarToolbar: {
        type: Boolean,
        default: () => (false),
      },
      avatarSize: {
        type: Number,
        default: () => (100),
      },
      isCardActionsVisible: {
        type: Boolean,
        default: true,
      },
      isBottomToolbarVisible: {
        type: Boolean,
        default: true,
      },
      isSubmitted: {
        type: Boolean,
        default: false,
      },
      nrImagesToTake: {
        type: Number,
        default: 5,
      },
      imageCountIndex: {
        type: Number,
        default: undefined,
      },
      showCloseButton: {
        type: Boolean,
        default: true,
      },
    },

    data () {
      return {
        offsetTop: 0,
        showSupportPrompt: false,
      }
    },

    computed: {
      nextButtonText () {
        const _firstStep = 0
        const _finalSubmitStep = (this.steps.length - 2)
        const _finishedStep = (this.steps.length - 1)

        let text
        if (this.internalValue === _firstStep) {
          text = this.$t('WIDGET_START_BUTTON_TEXT')
        } else if (this.internalValue === _finalSubmitStep) {
          text = this.$t('WIDGET_FINISH_BUTTON_TEXT')
        } else if (this.internalValue === _finishedStep) {
          text = ''
        } else {
          text = this.$t('WIDGET_NEXT_BUTTON_TEXT')
        }
        return text
      },
      isFirstStep () {
        return (this.internalValue === 0)
      },
      isSecondStep () {
        return (this.internalValue === (this.isFirstStep + 1))
      },
      isFinalSubmitStep () {
        return (this.internalValue === (this.steps.length - 2))
      },
      isFinishedStep () {
        return (this.internalValue === (this.steps.length - 1))
      },
      avatarToolbarOpacity () {
        const opacityDenominator = ((this.offsetTop) * 0.1) || 1
        let opacity = 1 / opacityDenominator
        if (opacity < 0) {
          opacity = 1 // NOTE: Happens on mobile when "scrolling upwards"
        } else if (opacity < 0.1) {
          opacity = 0
        }
        return opacity
      },
      primaryColor () {
        return this.$vuetify.theme.themes.light.primary
      },
      primaryColorDarken () {
        const darken = -15
        return this.shadeColor(this.$vuetify.theme.themes.light.primary, darken)
      },
      initialStartButtonColor () {
        return this.$vuetify.theme.themes.light.cta
      },
      isStepsVisible () {
        let _isStepsVisible = false
        const isImageStep = (this.imageCountIndex >= 0)
        if (isImageStep) {
          _isStepsVisible = true
        }
        return _isStepsVisible
      },
    },

    methods: {
      async next () {
        if (!this.isFirstStep) {
          // NOTE: There is a small paranoia that this "scroll to to" may cause
          //   the first button to need two clicks to react correclty. Testing.
          await this.$vuetify.goTo(0, 0)
        }
        this.$emit('click:next')
      },
      isMobile () {
        return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
      },
      getHeightByConstraint () {
        if (this.isMobile()) {
          return null
        } else {
          return 740
        }
      },
      onScroll (e) {
        this.offsetTop = window.scrollY
      },
      shadeColor (color, percent) {
        return colorUtils.shadeColor(color, percent)
      },
    },
  }
</script>

<style lang="sass">
  .v-card--wizard
    overflow: visible

    .v-tabs-bar
      height: 40px
      padding: 0 8px

    .v-tabs-slider-wrapper
      overflow: visible

    .v-tabs-slider
      border-radius: 4px

    .v-tabs-slider-wrapper
      contain: initial
      z-index: 0

    .display-2
      font-size: 24px !important

  .inner
    position: absolute

  .scroll
   overflow-y: scroll

  .transparent
    background-color: white!important
    opacity: 0.65
    border-color: transparent!important

</style>
