<template>
  <div>
    <v-card class="mb-12">
      <v-card-title class="widget-card-title">
        {{ $t('WHEN_IS_IT_GOOD_CARD_TITLE') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('WHEN_IS_IT_GOOD_CARD_SUBTITLE') }}
      </v-card-subtitle>
      <div
        v-if="demoVideoId"
        class="text-center"
        :class="`${isMobile() ? 'mt-n12 mb-n12' : ''}`"
      >
        <vimeo-player
          :player-width="videoDimensions.width"
          :video-id="demoVideoId"
        />
      </div>
    </v-card>
  </div>
</template>

<script>
  import dimensionUtils from '@/helpers/video_dimensions'

  export default {
    name: 'VideoOrImageOrNone',

    data () {
      return {
        videoOverlay: false,
        absolute: false,
        opacity: 1.0,
      }
    },

    computed: {
      demoVideoId () {
        return this.$t('WHEN_IS_IT_GOOD_CARD_VIMEO_VIDEO_ID')
      },
      videoDimensions () {
        return dimensionUtils.videoDimensions({ window: window })
      },
    },

    methods: {
      isMobile () {
        return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
      },
    },
  }
</script>

<style lang="sass">
  .widget-display-2
    font-size: 18px !important

  .widget-card-title
    font-size: 16px !important
</style>
