import axiosS3 from 'axios'
import axiosApi from '@/helpers/axios'

import parserUtils from './parser_utils'

const _CONTENT_TYPE_IMAGE_JPEG = 'image/jpeg'

var imageApiEndpoints = {
  async getPresignedUrls ({ assessmentUuid }) {
    const request = {
      data: {
        type: 'image-slots',
        attributes: {
          uuid: assessmentUuid,
        },
      },
    }
    const baseUrl = process.env.VUE_APP_API_ASSESSMENTS_URL
    return axiosApi.post(`${baseUrl}/public-image-slots/`, request)
  },

  async parsePresignedUrls ({ responseAsJsonApi }) {
    const uploadImageResources = await parserUtils.parseImageSlotsToUploadImageResources(responseAsJsonApi.data.data)
    return uploadImageResources
  },

  uploadImage ({ url, file, timeout = 60000 }) {
    const axiosSession = axiosS3.create({
      timeout: timeout,
      headers: { 'Content-Type': _CONTENT_TYPE_IMAGE_JPEG },
    })
    let config = {
      onUploadProgress: function (progressEvent) {
        // TODO: How to access the 'percentCompleted' during upload
        // let percentCompleted
        Math.round((progressEvent.loaded * 100) / progressEvent.total)
      },
    }
    const response = axiosSession.put(url, file, config)
    return response
  },

  async _downloadAsBlobWithoutCache ({ url }) {
    const axiosSession = axiosS3.create({
      headers: { 'Content-Type': _CONTENT_TYPE_IMAGE_JPEG },
    })
    const response = await axiosSession.get(url, {
      responseType: 'arraybuffer',
    })
    const blob = Buffer.from(response.data, 'binary')
    return blob
  },

  async checkConnectionByUploadSpeed () {
    // TODO: Consider this case in Australia.
    const downloadUrl = 'https://adent-upload-speed.s3.eu-central-1.amazonaws.com/download.jpg'
    const uploadUrl = 'https://adent-upload-speed.s3.eu-central-1.amazonaws.com/uploads/upload.jpg'

    // Download pre-determined file
    const blob = await this._downloadAsBlobWithoutCache({ url: downloadUrl })

    // Upload pre-determined file
    const t0 = Date.now()
    await this.uploadImage({ url: uploadUrl, file: blob })
    const t1 = Date.now()

    // Time upload
    const uploadTime = t1 - t0

    // Tranform to some rough measure of upload speed
    let _tag = ''
    const _fast = 800 // Arbitrarily set. See logging in airbrake to inform over time.
    const _slow = 3000
    if (uploadTime <= _fast) {
      _tag = 'fast'
    } else if (uploadTime > _fast && uploadTime <= _slow) {
      _tag = 'medium'
    } else if (uploadTime > _slow) {
      _tag = 'slow'
    }

    let bytesPrMs = 0
    try {
      bytesPrMs = blob.byteLength / uploadTime
    } catch (e) {
      bytesPrMs = e.message
    }

    const estimatedUploadSpeed = {
      tag: _tag,
      ms: uploadTime,
      bytesPrMs: bytesPrMs,
      message: new UploadTime(`Upload time: ${uploadTime}. Approx. internet speed (bytes/ms): ${bytesPrMs} (tagged: ${_tag})`),
    }
    return estimatedUploadSpeed
  },
}

class UploadTime extends Error {
  /** HACK. Used to send with Airbrake.
   */
  constructor (message) {
    super(message) // (1)
    this.name = 'UploadTime' // (2)
  }
}

export default imageApiEndpoints
