import axiosApi from '@/helpers/axios'

var submitApiServices = {
  analyticsForBaseInfo (assessmentUuid) {
    const request = this._assessmentReferenceAsJsonAPi(assessmentUuid)
    const endpoint = 'public-assessments/analytics_for_base_info_given/'
    return axiosApi.post(`${process.env.VUE_APP_API_ASSESSMENTS_URL}/${endpoint}`, request)
  },

  analyticsToInitializeWidgetAndDeviceStats ({ assessmentUuid, trafficSource, sessionId }) {
    const request = {
      data: {
        type: 'assessments',
        id: assessmentUuid,
        attributes: {
          uuid: assessmentUuid,
          session_id: sessionId,
          traffic_source: trafficSource, // DEPRECATE
          origin_url_as_base64: trafficSource,
        },
      },
    }
    const endpoint = 'public-assessments/analytics_to_initialize_widget/'
    return axiosApi.post(`${process.env.VUE_APP_API_ASSESSMENTS_URL}/${endpoint}`, request)
  },

  _assessmentReferenceAsJsonAPi (assessmentUuid) {
    return {
      data: {
        type: 'assessments',
        id: assessmentUuid,
        attributes: {
          uuid: assessmentUuid,
        },
      },
    }
  },
}

export default submitApiServices
