const overviewImages = [
    {
      name: '1',
      pointOfView: 'bite-front',
      url: 'https://static-onsite-toolkit.s3.eu-central-1.amazonaws.com/images/bite_front.jpg',
    },
    {
      name: '2',
      pointOfView: 'bite-left',
      url: 'https://static-onsite-toolkit.s3.eu-central-1.amazonaws.com/images/bite_left.jpg',
    },
    {
      name: '3',
      pointOfView: 'bite-right',
      url: 'https://static-onsite-toolkit.s3.eu-central-1.amazonaws.com/images/bite_right.jpg',
    },
    {
      name: '4',
      pointOfView: 'upper-arch',
      url: 'https://static-onsite-toolkit.s3.eu-central-1.amazonaws.com/images/upper_arch.jpg',
    },
    {
      name: '5',
      pointOfView: 'bottom-arch',
      url: 'https://static-onsite-toolkit.s3.eu-central-1.amazonaws.com/images/lower_arch.jpg',
    },
    {
      name: '6',
      pointOfView: '*',
      url: 'https://static-onsite-toolkit.s3.eu-central-1.amazonaws.com/images/photo_with_flash.jpg',
    },
  ]

export default Object.freeze(overviewImages)
